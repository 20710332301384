import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './interceptor/axios'
import AdmissionLayoutView from './views/master/AdmissionLayoutView'





const app = createApp(App)
app.use(store)
app.use(router)
app.component('AdmissionLayoutView',AdmissionLayoutView);


app.mount('#app')




