
import { createRouter, createWebHistory } from "vue-router";
// import AdmissionDetailView from "@/views/pages/EduProviderDetail.vue";
import Education from "@/views/education/Education.vue";
import EduFAQ from "@/views/education/EduFAQ.vue";
import EduSchool from "@/views/education/EduSchool.vue";
import EduVET from "@/views/education/EduVET.vue";
import EduUniversity from "@/views/education/EduUniversity.vue";
import EduTerms from "@/views/education/EduTerms.vue";
import EduPrivacy from "@/views/education/EduPrivacy.vue";
import EduContact from "@/views/education/EduContact.vue";



import EduProvider from "@/views/pages/EduProvider.vue";
import EduProviderDetail from "@/views/pages/EduProviderDetail.vue";


const routes = [

  {
    path: "/",
    name: "education",
    meta: {layout: "education"},
    component: Education
  },
  {
    path: "/edu-faq",
    name: "EduFAQ",
    meta: {layout: "education"},
    component: EduFAQ
  },
  {
    path: "/edu-school",
    name: "EduSchool",
    meta: {layout: "education"},
    component: EduSchool
  },
  {
    path: "/edu-vet",
    name: "EduVET",
    meta: {layout: "education"},
    component: EduVET
  },
  {
    path: "/edu-university",
    name: "EduUniversity",
    meta: {layout: "education"},
    component: EduUniversity
  },
  {
    path: "/edu-terms",
    name: "EduTerms",
    meta: {layout: "education"},
    component: EduTerms
  },
  {
    path: "/edu-privacy",
    name: "EduPrivacy",
    meta: {layout: "education"},
    component: EduPrivacy
  },
  {
    path: "/edu-contact",
    name: "EduContact",
    meta: {layout: "education"},
    component: EduContact,
  },

  {
    path: "/edu-provider",
    name: "EduProvider",
    component: EduProvider,
    meta: {layout: "education"}
  },
  {
    path: "/edu-provider-detail",
    name: "EduProviderDetail",
    component: EduProviderDetail,
    meta: {layout: "education"}
  },

  // msp section end
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;

