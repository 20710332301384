<template>
   <dashboard-master-view v-if="this.$route.meta.layout==='admin'" />
   <master-view  v-else-if="this.$route.meta.layout==null"  />
   <auth-master-view  v-else-if="this.$route.meta.layout=='auth'"  />
   <admission-layout-view v-else-if="this.$route.meta.layout=='education'"/>
   </template>
   
   <script>
   import DashboardMasterView from './views/master/DashboardMasterView'
   import MasterView from './views/master/MasterView'
   import AuthMasterView from './views/master/AuthMasterView'
   import AdmissionLayoutView from "@/views/master/AdmissionLayoutView.vue";
   export default {
      components:{AdmissionLayoutView, DashboardMasterView,MasterView,AuthMasterView}
   }
   </script>
   
 