<template>
  <header class="main-header">
    <div class="container">

      <!-- Main box -->
      <div class="main-box">
        <!--Nav Outer -->
        <div class="logo-box">
          <div class="logo">
            <a href="/">
              <img src="images/jobchain-edu.svg" alt="Logo" />
            </a>
          </div>
        </div>

        <nav class="nav main-menu">
          <ul class="navigation" id="navbar">
            <li><a href="/">Home</a></li>

            <li class="dropdown dropdown-option">
              <a>Australian Education</a>
              <ul class="dropdown-menu">
                <li><a href="/edu-school">School</a></li>
                <li><a href="/edu-vet">VET</a></li>
                <li><a href="/edu-university">University</a></li>
              </ul>
            </li>
            <li><a href="/edu-provider">Education Providers</a></li>
            <li><a href="/edu-faq">FAQ</a></li>
<!--            <li><a href="/edu-terms" target="_blank">Terms</a></li>-->
<!--            <li><a href="/edu-privacy" target="_blank">Policy</a></li>-->


            <!-- Only for Mobile View -->
            <!--              <li class="mm-add-listing">-->
            <!--                &lt;!&ndash; <a href="add-listing.html" class="theme-btn btn-style-one"-->
            <!--                  >Job Post</a-->
            <!--                > &ndash;&gt;-->
            <!--                <span>-->
            <!--                  <span class="contact-info">-->
            <!--                    <span class="phone-num"-->
            <!--                    ><span>Call us</span-->
            <!--                    ><a href="tel:+44 7495 587992">+44 7495 587992</a></span-->
            <!--                    >-->
            <!--                    <span class="address"-->
            <!--                    >128-130 the Grove,Startford,London,England,E15 1NS-->
            <!--                    </span>-->
            <!--                    <a href="mailto:support@jobchains.com" class="email"-->
            <!--                    >support@jobchain.co.uk</a-->
            <!--                    >-->
            <!--                  </span>-->
            <!--                  <span class="social-links">-->
            <!--                    <a href="#"><span class="fab fa-facebook-f"></span></a>-->
            <!--                    <a href="#"><span class="fab fa-twitter"></span></a>-->
            <!--                    <a href="#"><span class="fab fa-instagram"></span></a>-->
            <!--                    <a href="#"><span class="fab fa-linkedin-in"></span></a>-->
            <!--                  </span>-->
            <!--                </span>-->
            <!--              </li>-->
          </ul>
        </nav>
        <!-- Main Menu End-->

        <div class="outer-box d-none">
          <!-- Login/Register -->
          <div class="btn-box text-end">
            <a href="/edu-contact" class="btn btn-outline-primary">
              Contact Us <span class="fa fa-arrow-right"></span>
            </a>
          </div>
        </div>

      </div>

      <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo">
          <a href="/">
            <!--            <img src="images/logo/logo.png" alt=""/>-->
            <img src="images/jobchain-square.png" alt=""/>
          </a>
        </div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">
          <div class="outer-box text-black">
            <!-- Login/Register -->
<!--            <div class="login-box">-->
<!--              <a class="text-black" @click.prevent="dashboard">-->
<!--                <span class="las la-user fs-1"></span>-->
<!--              </a>-->
<!--            </div>-->

            <a href="#nav-mobile" class="mobile-nav-toggler text-black">
              <span class="flaticon-menu-1"></span>
            </a>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->
      <div id="nav-mobile"></div>
    </div>

  </header><!-- Mute end -->
</template>

<script>
export default {
  name: "EduHeader",

};
</script>

<style scoped>

/* Hero section */
.h1-primary-highlight {
  font-size: 40px;
  font-weight: 700;
  color: #FF7600;
}

/* Main menu */
.main-header .logo img {
  height: 55px;
}

.main-menu .navigation > li > ul {
  border-top: 2px solid #FF7600;
}

.main-menu .navigation > li > ul::before {
  border-bottom: 5px solid #FF7600;
}

.main-menu .navigation > li > ul > li > span:hover, .main-menu .navigation > li > ul > li > a:hover {
  color: #FF7600;
}

</style>
