<template>
  <div class="contact-us">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="tb-content">
          <h2>Contact Us (Education)</h2>
        </div>

      </div>
    </div>

    <div class="container">
      <!-- Contact Form -->
      <div class="contact-form default-form p-4">
        <!--                  <div class="header mb-5">-->
        <!--                    <div class="img-wrap">-->
        <!--                      <img-->
        <!--                          :src="ano_uni_view.logo"-->
        <!--                          alt="logo"-->
        <!--                      />-->
        <!--                      &lt;!&ndash;                  <img src="../../assets/logo.png" alt="logo">&ndash;&gt;-->
        <!--                    </div>-->
        <!--                    <div class="content">-->
        <!--                      <h6 class="job-title">{{ ano_uni_view.name }}</h6>-->
        <!--                      <p>-->
        <!--                        <i class="fa fa-map-marker-alt"></i> {{ ano_uni_view.city }}, {{ ano_uni_view.country }}-->
        <!--                        | <i class="fa fa-link"></i> <a :href="ano_uni_view.website" target="_blank">Website link</a>-->

        <!--                      </p>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <h4>Request for more information!</h4>
        <!--Contact Form-->
        <form @submit.prevent="add_admission_Query">
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <div class="response"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>Full Name</label>
              <input
                  v-model="full_name"
                  type="text"
                  class="username"
                  placeholder="Full Name*"

                  @input="clearError('full_name')"
              />
              <div id="full_name" class="text-danger error"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>Nearby Branch</label>
              <select  class="form-select"
                       v-model="office"
                       @input="clearError('office')"
              >
                <option disabled :value="null">
                  Select Nearby Branch
                </option>
                <option
                    v-for="office in offices"
                    :key="office.id"
                    :value="office.id"
                >
                  {{ office.city }}, {{ office.country }}
                </option>
                <!-- <option selected>Select Branch</option>
                <option value="1">Dhaka, Bangladesh</option>
                <option value="2">KL, Malaysia</option> -->
              </select>

              <div id="office" class="text-danger error"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>University</label>
              <select  class="form-select"
                       v-model="university"
                       @input="clearError('university')"
              >
                <option disabled :value="null">
                  Select university
                </option>
                <option
                    v-for="uni in unidata"
                    :key="uni.id"
                    :value="uni.id"
                >
                  {{ uni.name }}
                </option>
                <!-- <option selected>Select Branch</option>
                <option value="1">Dhaka, Bangladesh</option>
                <option value="2">KL, Malaysia</option> -->
              </select>

              <div id="university" class="text-danger error"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>Phone(+country code)</label>
              <input
                  v-model="phone"
                  type="text"
                  class="username"
                  placeholder="Phone*"
                  @input="clearError('phone')"
              />
              <div id="phone" class="text-danger error"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>Email</label>
              <input
                  v-model="email"
                  type="email"
                  class="email"
                  placeholder="Email*"
                  @input="clearError('email')"
              />
              <div id="email" class="text-danger error"></div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>City</label>
              <input
                  v-model="city"
                  type="text"
                  class="username"
                  placeholder="City*"
                  @input="clearError('city')"
              />
              <div id="city" class="text-danger error"></div>
            </div>

            <!--                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">-->
            <!--                          <label>Country</label>-->
            <!--                          <input-->
            <!--                              type="email"-->
            <!--                              class="email"-->
            <!--                              placeholder="Country*"-->
            <!--                              required-->
            <!--                          />-->
            <!--                        </div>-->

            <div class="col-lg-6 col-md-12 col-sm-12 form-group">
              <label>Country</label>
              <select
                  v-model="country"
                  @input="clearError('country')"
                  class="form-select">
                <option disabled value="null">Select country</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Thailand">Thailand</option>
                <option value="Australia">Australia</option>
              </select>
              <div id="country" class="text-danger error"></div>
            </div>



            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <button class="btn btn-primary"
                      type="submit" id="submit"
                      name="submit-form">Send Massage</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "EduContact",

  data() {
    return {
      offices: [],
      unidata:{},
      ano_uni_view: {},

      university: null,
      office: null,
      country: null,

      //Pagenation
      // current_page: 1,
      // next_page: "",
      // next: "",
      // previous: "",
      // pages: 1,
      // total_item: "",
      // all_data: {},
    };
  },

  methods:{

    get_office() {
      axios
          .get("anonymous/admission_office/")
          .then((res) => {
            console.log('full res: ', res.data);
            this.offices = res.data;

          })
          .catch((error) => {
            console.log('error.response', error.response);
          });
    },

    get_university() {
      axios
          .get("anonymous/university/")
          .then((res) => {
            console.log('full res: ', res.data.results);
            this.unidata = res.data.results;

          })
          .catch((error) => {
            console.log('error.response', error.response);
          });
    },

    get_university_details(uuid) {
      axios
          .get("anonymous/university/" + uuid + "/")
          .then((res) => {
            this.ano_uni_view = res.data;





            var loader = document.getElementById("loader-small");
            loader.style.display = "none";

            if (res.status == 200) {
              console.log("done");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },


    add_admission_Query() {
      if (this.full_name== null || this.full_name == "") {
        this.nullcheck("full_name");
      } else if (this.office == null || this.office == "") {
        this.nullcheck("office");
      } else if (this.university == null || this.university == "") {
        this.nullcheck("university");
      } else if (this.phone == null || this.phone == "") {
        this.nullcheck("phone");
      } else if (this.email == null || this.email == "") {
        this.nullcheck("email");
      } else if (this.city == null || this.city == "") {
        this.nullcheck("city");
      } else if (this.country == null || this.country == "") {
        this.nullcheck("country");
      }
      else {
        axios
            .post("anonymous/admission_query/", {
              full_name: this.full_name,
              office: this.office,
              university: this.university,
              phone: this.phone,
              email: this.email,
              city: this.city,
              country: this.country,

            })
            .then((res) => {
              if (res.status == 201) {
                this.full_name =null;
                this.office = null;
                this.university = null;
                this.phone = null;
                this.email = null;
                this.city = null;
                this.country = null;

                console.log('admission response: ', res);

                this.$emit("get_message", "Request Successful");
                alert('Request Successful');
              }
            })
            .catch((error) => {
              console.log(error.response);

              this.$emit("get_message", " Your Request already exists");
              alert('Your Request already exists');
            });
      }
    },


  },

  mounted: function () {
    this.get_office();
    this.get_university();
    // this.get_university_details();
    var uuid = this.$route.query.uuid;
    var loader = document.getElementById("loader-small");
    loader.style.display = "block";

    if (uuid != null) {
      this.get_university_details(uuid);
      window.scrollTo(0, 0);
    }
  },

};
</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

.tb-content {
  display: grid;
  align-items: center;
  min-height: 150px;
}

.tb-content h2 {
  font-size: 30px;
  font-weight: 200;
}

/* Title banner end */

.contact-form {
  padding-top: 50px !important;
  padding-bottom: 30px !important;
  max-width: 730px;
  margin: 0 auto;
}

</style>
