<template>
  <footer class="main-footer prod-footer style-two theme-color-bg2 ">
    <div class="auto-container">
      <!--Widgets Section-->
      <div class="widgets-section mb-3">
        <div class="row g-3">
          <div class="footer-columnx col-sm-6 col-md-4">
            <div class="footer-column about-widget">
              <div class="logo">
                <a href="/">
                  <img style="height: 58px;"
                       src="images/jobchain/edu-logo-white.png"/>
                </a>
              </div>
              <p class="phone-num">
                <strong>Suite: </strong>
                Suite: 103, Level 1
              </p>
              <p class="phone-num">
                <!--                <strong>Present Address: </strong>-->
                661 George Street, Haymarket NSW 2000
              </p>
              <p class="phone-num">
                <strong>ABN: </strong>
                18 672 565 570
              </p>
<!--              <p class="phone-num">-->
<!--                <strong>Mobile: </strong>-->
<!--                +66 80 639 2313-->
<!--              </p>-->
              <p class="phone-num">
                <strong>Email: </strong>
                admin@jobchainedu.com.au
              </p>

              <div class="jc-social-media">
                <h6>Social With Us</h6>
                <ul class="text-white">
                  <li>
                    <a target="_blank" href="https://www.fb.com/JobChain.Edu/">
                      <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-facebook"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-youtube"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-twitter"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                </ul>
              </div>
            </div>
          </div>

          <div class="footer-columnx col-sm-6 col-md-4">
            <div class="footer-widget links-widget">
              <h4 class="widget-title">Quick Links</h4>
              <div class="widget-content d-flex">
                <ul class="list">
                  <li><a href="/edu-terms">Terms & Conditions</a></li>
                  <li><a href="/edu-privacy">Privacy Policy</a></li>
                  <li><a href="/edu-faq">FAQ</a></li>
                  <li><a href="/edu-contact">Contact Us</a></li>
                  <!--                      <ul class="list">-->
                  <!--                        <li><a href="/needhelp">Support</a></li>-->
                  <!--                        <li><a href="/howitworks">How It Works</a></li>-->


                  <!--                        <li><a href="/contact">Contact Us</a></li>-->
                  <!--                      </ul>-->
                </ul>


              </div>
            </div>
          </div>
          <div class="footer-columnx col-sm-6 col-md-4">
            <div class="footer-widget links-widget">
              <h4 class="widget-title">Location</h4>
              <div class="google-map">
                <iframe
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=379%20Pitt%20Street,%20Sydney,%20NW%202000+(JobChain)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    width="100%" height="300"
                    style="border:0; border-radius: 5px"
                    allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>



              </div>
            </div>
          </div>
<!--          <div class="footer-columnx col-sm-6 col-md-4">-->
<!--            <div class="footer-widget">-->
<!--              <h4 class="widget-title">Join Us On</h4>-->
<!--              <div class="widget-content">-->
<!--                <div class="newsletter-form">-->
<!--                  <div class="text">Please be assured, we do not send spam.</div>-->
<!--                  <form @submit.prevent="newsletter_message" id="subscribe-form">-->

<!--                    <div class="form-group">-->
<!--                      <input type="text" v-model="email" class="email"-->
<!--                             @input="email_error = ''"-->
<!--                             placeholder="Email">-->


<!--                      <button type="submit" id="subscribe-newslatters" class="theme-btn">-->
<!--                        <i class="fa fa-paper-plane"></i></button>-->
<!--                    </div>-->
<!--                    <div class="form-group text-white">-->
<!--                      <div v-if="email_error != ''">-->
<!--                        {{ email_error }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </form>-->

<!--                  <div id="loader-small">-->
<!--                    <span class="loading__dot_small"></span>-->
<!--                    <span class="loading__dot_small"></span>-->
<!--                    <span class="loading__dot_small"></span>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->


        </div>
      </div>
    </div>


    <!--Copyright section-->
    <div class="copyright-wrap">
      <div class="container">
        <div class="copyright-content">
          <div class="left-copyright">
            © 2023 <a href="#">JobChain Education Pty Ltd</a>. All Right Reserved.
          </div>
          <div class="right-copyright">
            <ul class="powered-by">
              <li>
                <a href="https://www.datafluent.xyz/" target="_blank">
                  <img src="../../assets/dfa_logo_white.png" alt="dfa_logo">
                </a>
              </li>
              <li>
                Powered by
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--Bottom-->
    <!--    <div class="footer-bottom">-->
    <!--      <div class="auto-container">-->
    <!--        <div class="outer-box">-->
    <!--          <div class="copyright-text">© 2023 <a href="#">JobChain Pty Ltd</a>. All Right Reserved.</div>-->

    <!--&lt;!&ndash;          <div class="copyright-text">&ndash;&gt;-->
    <!--&lt;!&ndash;            <strong>Powered by </strong>&ndash;&gt;-->
    <!--&lt;!&ndash;            <a href="/">DataFluent Analytics</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->

    <!--          <ul class="powered-by">-->
    <!--            <li>-->
    <!--              <a href="https://www.datafluent.xyz/" target="_blank">-->
    <!--                <img src="../../assets/dfa_logo.svg" alt="dfa_logo">-->
    <!--              </a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          &lt;!&ndash; <div class="social-links">-->
    <!--            <a href="https://www.facebook.com/profile.php?id=100093448454141"><i class="fab fa-facebook-f"></i></a>-->
    <!--            <a href="https://twitter.com/home"><i class="fab fa-twitter"></i></a>-->
    <!--            <a href="#"><i class="fab fa-instagram"></i></a>-->
    <!--            <a href="https://www.linkedin.com/company/95059378/admin/"><i class="fab fa-linkedin-in"></i></a>-->
    <!--          </div> &ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
<!--    <div class="scroll-to-top scroll-to-target" data-target="html">-->
<!--      <span class="fa fa-angle-up"></span>-->
<!--    </div>-->
  </footer>

</template>

<script>
import axios from 'axios'

export default {
  name: "EduFooter",

  data() {
    return {
      show_message: "",
      email_error: ''
    }
  },


  methods: {

    newsletter_message() {


      if (this.email == "" || this.email == null) {
        this.email_error = "Email is required"


      } else if (!this.email.includes("@")) {
        this.email_error = "Email is not valid"

      } else {
        var loader = document.getElementById("loader-small");
        loader.style.display = "block";

        axios.post("anonymous/newsletter/", {
              email: this.email,

            }
            , {ignore: "1"}).then(res => {

              if (res.status == 200) {
                this.show_message = res.data.status
                var loader = document.getElementById("loader-small");
                loader.style.display = "none";
                this.email = ""

                this.$emit('get_message', this.show_message)


              }

            }
        )
            .catch(error => {
              console.log(error.response)
            });

      }

    },


  },

}
</script>

<style scoped>
/*.footer-columnx {border: 1px solid #eee}*/
.google-map {
  /*margin-right: 30px;*/
}

.jc-social-media {
  margin-top: 30px;
  color: white;
}

.jc-social-media h6 {
  margin-bottom: 15px;
  font-size: 14px;
}

.jc-social-media ul {
  display: flex;
  gap: 15px;
  align-items: center;
}

.jc-social-media ul li a {
  display: block;
  border: 1px solid #FFE2BF;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
  color: #FF7600;
  line-height: 24px;
  font-size: 14px;
  background-color: #FFE2BF;
}

.jc-social-media ul li a:hover {
  color: #B36200;
  /*background-color: transparent;*/
  transition: color ease-out 0.5s;
}

/* Copyright Section */
.copyright-wrap {
  background-color: black;
  color: white;
  padding: 15px 0;
  border-top: 1px solid #333;
}

.copyright-content {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  line-height: normal;
  align-items: center;
}

ul.powered-by {
  display: flex;
  gap: 5px;
  flex-direction: row-reverse;
  align-items: center;
}

ul.powered-by li {
  font-size: 10px;
}

ul.powered-by li a {
  display: block;
  font-size: 10px;
}

ul.powered-by li a img {
  padding: 5px;
  height: 30px;
}

.left-copyright {
  font-size: 12px;
}

.left-copyright a {
  color: #FF7600;
  font-weight: 300;
}
</style>