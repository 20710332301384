<template>
  <div class="need-help-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="tb-content">
          <h2>Education FAQ</h2>
          <!--          <p></p>-->
        </div>

      </div>
    </div>

    <div class="container">
      <!-- FAQ -->
      <div class="faq-wrap">
        <div class="faq-list mb-5">
          <h4>
            Frequently Asked Questions for International Student Recruiters in Australia
          </h4>
          <div class="accordion" id="accordionParent">
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#whatJC"
                >
                  1. Why should I choose to study in Australia?
                </button>
              </h2>

              <div id="whatJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Australia is known for its high-quality education system and world-class universities.
                  It offers a diverse and multicultural environment that welcomes international students.
                  The country has a strong economy and excellent job opportunities for graduates.
                </div>
              </div>
            </div>

            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#createAcountJC"
                >
                  2. How do I apply for a student visa to Australia?
                </button>
              </h2>

              <div id="createAcountJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: You can apply for a student visa online through the Department of Home Affairs website.
                  Make sure you have your Confirmation of Enrollment (CoE) from your chosen institution before applying.
                  Provide required documents like a valid passport, proof of financial capacity, and health insurance.
                </div>
              </div>
            </div>

            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#freeUseJC"
                >
                  3. What are the English language proficiency requirements for international students?
                </button>
              </h2>

              <div id="freeUseJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: International students generally need to prove their English proficiency through tests like IELTS, TOEFL, or PTE.
                  The required score may vary depending on the course and institution, so check with your chosen university.
                </div>
              </div>
            </div>

            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#isEmployerPay"
                >
                  4. Can I work while studying in Australia?
                </button>
              </h2>

              <div id="isEmployerPay" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Yes, international students on a student visa can work part-time during their studies, typically up to 40 hours per fortnight.
                  During scheduled breaks, you can work unlimited hours.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#searchJL"
                >
                  5. How do I find part-time work in Australia as an international student?
                </button>
              </h2>

              <div id="searchJL" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Universities often have career services to help students find part-time jobs.
                  You can also explore job search websites, network with fellow students, and attend job fairs.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#isUpCV"
                >
                  6. What is the cost of living for international students in Australia?
                </button>
              </h2>

              <div id="isUpCV" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: The cost of living varies by city, with Sydney and Melbourne generally being more expensive.
                  On average, you should budget for accommodation, food, transportation, and other expenses, which can range from AUD 18,000 to AUD 30,000 per year.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#applyJC"
                >
                  7. Are there scholarships available for international students in Australia?
                </button>
              </h2>

              <div id="applyJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Yes, many Australian universities and the Australian government offer scholarships to international students.
                  Eligibility criteria and application deadlines may vary, so research and apply early.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ppJC"
                >
                  8. Can I work in Australia after completing my studies?
                </button>
              </h2>

              <div id="ppJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Yes, Australia offers a post-study work visa (PSWV) to international graduates. The duration of this visa depends on your level of study.
                  The PSWV allows you to work and gain practical experience in your field.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#isEDAcount"
                >
                  9. What are the healthcare options for international students in Australia?
                </button>
              </h2>

              <div id="isEDAcount" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Australia has a healthcare system called Medicare, but international students usually need to purchase Overseas Student Health Cover (OSHC) for their visa.
                  OSHC helps cover medical expenses while in Australia.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#supportJC"
                >
                  10. How do I find accommodation as an international student in Australia?
                </button>
              </h2>

              <div id="supportJC" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Most universities offer on-campus accommodation, which you can apply for through their housing
                  services. - You can also explore off-campus options like rental apartments, shared houses, or hostels.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#additionalFeature"
                >
                  11. What are the key academic dates and important events for international students?
                </button>
              </h2>

              <div id="additionalFeature" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Academic calendars vary by university, so it's essential to check the specific dates with your
                  institution. - Major events for international students include orientation, cultural festivals, and
                  academic support services.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#postAds"
                >
                  12. How can I adapt to the Australian education system and culture as an international student?
                </button>
              </h2>

              <div id="postAds" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Most universities offer orientation programs to help you acclimate to the new environment. -
                  Joining student clubs, engaging in cultural exchange, and seeking support from student services can
                  also enhance your experience.
                </div>
              </div>
            </div>
            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regulationsAusStudent"
                >
                  13. What are the immigration and visa regulations for international students in Australia?
                </button>
              </h2>

              <div id="regulationsAusStudent" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: It's essential to follow the visa regulations, maintain your CoE, and meet attendance and
                  academic requirements to keep your student visa valid. - Be aware of any updates or changes to
                  immigration regulations by regularly checking the Department of Home Affairs website.
                </div>
              </div>
            </div>

            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#extracurricularAct"
                >
                  14. Are there opportunities for international students to engage in extracurricular activities?
                </button>
              </h2>

              <div id="extracurricularAct" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: Yes, Australian universities offer a wide range of clubs, societies, and extracurricular
                  activities. - You can participate in sports, cultural events, and student organizations to enrich your
                  university experience.
                </div>
              </div>
            </div>

            <div class="accordion-item">

              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#familyReq"
                >
                  15. Can international students bring their families to Australia?
                </button>
              </h2>

              <div id="familyReq" class="accordion-collapse collapse"
                   data-bs-parent="#accordionParent">
                <div class="accordion-body">
                  Answer: International students can often bring their immediate family members to Australia on
                  dependent visas. - Check the specific requirements and conditions with the Department of Home Affairs.
                  <br><br> Remember that the information provided here is general, and it's essential to check with your
                  specific institution and the Australian government for the most accurate and up-to-date information
                  regarding your studies and stay in Australia.
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>


    </div>

  </div>

</template>

<script>
export default {
  name: "EduFAQ",
};

</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

.tb-content {
  display: grid;
  align-items: center;
  min-height: 150px;
}

.tb-content h2 {
  font-size: 30px;
  font-weight: 200;
}

/* Title banner end */

.faq-wrap {
  padding-top: 50px;
  padding-bottom: 30px;
}

.faq-list {
  /*max-width: 730px;*/
  /*margin: 0 auto;*/
}

.faq-list h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: normal;
}

</style>


