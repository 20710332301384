<template>
  <header class="main-header header-shaddow">
    <div class="container-fluid">
      <!-- Main box -->
      <div class="main-box">
        <!--Nav Outer -->
        <div class="nav-outer">
          <div class="logo-box">
            <div class="logo">
              <a href="/"
                ><img
                  style="height: 45px; width: 215px"
                  src="images/jobchain-logo-color.svg"
                  alt=""
                  title=""
              /></a>
              
            </div>
          </div>

          <nav class="nav main-menu">
            <ul class="navigation" id="navbar"></ul>
          </nav>
          <!-- Main Menu End-->
        </div>

        <div class="outer-box">
          <div class="dropdown dashboard-option" style="padding-right: 25px">
            <a
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="logo" alt="avatar" class="thumb" />
              <span class="name text-grey">{{ name }}</span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a href="/"><i class="la la-home"></i>Home</a>
              </li>

              <li>
                <a href="#"><i class="la la-user-alt"></i>View Profile</a>
              </li>
              <li>
                <a href="/change-password"
                  ><i class="la la-lock"></i>Change Password</a
                >
              </li>
              <li>
                <a @click.prevent="logout" href="#"
                  ><i class="la la-sign-out"></i>Logout</a
                >
              </li>
              <!--li><a href="index.html"><i class="la la-trash"></i>Delete Profile</a></li-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Header -->
    <div class="mobile-header">
      <div class="logo">
        <a href="/"><img src="images/jobchain-logo-color.svg" alt="" title="" /></a>
      </div>

      <!--Nav Box-->
      <div class="nav-outer clearfix">
        <div class="outer-box2">
          <!-- Login/Register -->
          <!-- <div class="login-box">
               <a href="login-popup.html" class="call-modal"><span class="icon-user"></span></a>
             </div> -->

          <button id="toggle-user-sidebar">
            <img :src="logo" alt="avatar" class="thumb" />
          </button>
          <a id="toggle-user-sidebar" class="mobile-nav-toggler navbar-trigger"
            ><span class="flaticon-menu-1"></span
          ></a>
        </div>
      </div>
    </div>

    <!-- Mobile Nav -->
    <div id="nav-mobile"></div>
  </header>
</template>

<script>
import vcookie from "vue-cookie";
import axios from "axios";
export default {
  name: "DashHeaderView",

  data() {
    return {
      name: "",
      logo: "",
      
    };
  },

  methods: {
    showprofile() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        this.name = user.user.first_name + " " + user.user.last_name;
        
        

        if (user.user.profile_pic != null) {
          this.logo = axios.defaults.baseURL + user.user.profile_pic;
          
        } else {
          this.logo = "images/user.png";
        }
      }
    },
    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router
          .push({ name: "login" })
          .then(() => {
            location.reload();
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
  },
  mounted: function () {
    this.showprofile();
  },
};
</script>

<style>
.main-header .main-box2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: -100px;
}
</style>
