<template>
  <div class="need-help-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="tb-content">
          <h2>Education VET</h2>
          <!--          <p></p>-->
        </div>

      </div>
    </div>

    <div class="container">
      <!--      <no-data></no-data>-->
      <div class="tc-box">
        <div class="content-wrap">
          <p class="mb-3">
            Australia's education system consists of three main segments: Schools, Vocational Education and Training
            (VET), and Universities. Each segment offers unique qualities, benefits, and entry requirements for
            international students:
          </p>

          <div class="group-content mb-3">
            <h4 class="title mb-2">Vocational Education and Training (VET)</h4>
            <div class="sub-title mb-2 jc-text-primary">Quality:</div>
            <p>
              VET institutions in Australia offer practical and skills-focused training in various industries, such as
              hospitality, automotive, healthcare, and more.
              Courses are designed to meet industry standards, ensuring students are job-ready upon graduation.
            </p>
          </div>
          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Benefits:</div>
            <p>
              VET programs are shorter in duration than university degrees, allowing students to enter the workforce
              faster.
              Graduates of VET courses often have excellent employment prospects and can work in skilled occupations.
              VET institutions offer a range of flexible learning options, including apprenticeships and on-the-job
              training.
            </p>
          </div>

          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Entry Requirements for International Students:</div>
            <p>
              International students wishing to study VET courses in Australia typically need to obtain a student visa.
              Some courses may have specific prerequisites, so students should check with the VET institution for
              admission requirements.
              English language proficiency requirements vary by course and institution, and students may need to provide
              proof of language skills.
            </p>
          </div>

          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Accommodation:</div>
            <p>
              VET institutions may offer on-campus accommodation, but availability varies.
              Students often seek accommodation off-campus, such as rental apartments, shared housing, or homestays.
              Housing costs will depend on the location and type of accommodation.
            </p>
          </div>

          <div class="group-content mt-5">
            <p>
              <strong>
                It's important for international students to research and carefully choose the education segment that
                aligns with their academic and career goals. Additionally, they should check the entry requirements and
                procedures of their chosen institution to ensure a smooth application process and successful enrollment
                in Australia.
              </strong>
            </p>
          </div>


        </div>

      </div>
    </div>

  </div>

</template>

<script>
// import NoData from "@/components/dashboard/NoData.vue";

export default {
  name: "EduVET",
  // components: {NoData},
};

</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

.tb-content {
  display: grid;
  align-items: center;
  min-height: 150px;
}

.tb-content h2 {
  font-size: 30px;
  font-weight: 200;
}

/* Title banner end */

.tc-box {
  padding: 50px 0;
  max-width: 800px;
  margin: 0 auto;
}

</style>


