<template>
  <footer class="main-footer prod-footer style-two theme-color-bg2 ">
    <div class="auto-container">
      <!--Widgets Section-->
      <div class="widgets-section mb-3">
        <div class="row g-3">
          <div class="footer-columnx col-sm-6 col-md-3">
            <div class="footer-column about-widget">
              <div class="logo">
                <a href="/">
                  <img style="height: 40px;"
                       src="images/logo/logo_white.png"/>
                </a>
              </div>
              <p class="phone-num">
                <!--                <strong>Present Address: </strong>-->
                Unit 1 266A Bunnerong Road, Hillsdale, NSW 2036
              </p>
              <p class="phone-num">
                <strong>ABN: </strong>
                41 670 575 050
              </p>
              <p class="phone-num">
                <strong>Email: </strong>
                support@jobchain.au
              </p>

              <div class="jc-social-media">
                <h6>Social With Us</h6>
                <ul class="text-white">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/company/job-chain-pty-ltd">
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </li>
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-facebook"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-youtube"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a href="">-->
                  <!--                      <span class="bi bi-twitter"></span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                </ul>
              </div>
            </div>
          </div>

          <div class="footer-columnx col-sm-6 col-md-3">
            <div class="footer-widget links-widget">
              <h4 class="widget-title">Quick Links</h4>
              <div class="widget-content d-flex">
                <ul class="list">
                  <li><a href="/terms">Terms & Conditions</a></li>
                  <li><a href="/privacy">Privacy Policy</a></li>
                  <li><a href="/login">Register/ Login</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                  <!--                      <ul class="list">-->
                  <!--                        <li><a href="/needhelp">Support</a></li>-->
                  <!--                        <li><a href="/howitworks">How It Works</a></li>-->


                  <!--                        <li><a href="/contact">Contact Us</a></li>-->
                  <!--                      </ul>-->
                </ul>


              </div>
            </div>
          </div>
          <div class="footer-columnx col-sm-6 col-md-3">
            <div class="footer-widget links-widget">
              <h4 class="widget-title">Location</h4>
              <div class="google-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.5340436966244!2d151.22779287556153!3d-33.95311062343043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b1610149f64d%3A0xc3bcc6c5ca8643f4!2sUnit%201%2F266A%20Bunnerong%20Rd%2C%20Hillsdale%20NSW%202036%2C%20Australia!5e0!3m2!1sen!2sbd!4v1696678278329!5m2!1sen!2sbd"
                    width="100%" height="200"
                    style="border:0; border-radius: 5px"
                    allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
            </div>
          </div>
          <div class="footer-columnx col-sm-6 col-md-3">
            <div class="footer-widget">
              <h4 class="widget-title">Join Us On</h4>
              <div class="widget-content">
                <div class="newsletter-form">
                  <div class="text">Please be assured, we do not send spam.</div>
                  <form @submit.prevent="newsletter_message" id="subscribe-form">

                    <div class="form-group">
                      <input type="text" v-model="email" class="email"
                             @input="email_error = ''"
                             placeholder="Email">


                      <button type="submit" id="subscribe-newslatters" class="theme-btn">
                        <i class="fa fa-paper-plane"></i></button>
                    </div>
                    <div class="form-group text-white">
                      <div v-if="email_error != ''">
                        {{ email_error }}
                      </div>
                    </div>
                  </form>

                  <div id="loader-small">
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


    <!--Copyright section-->
    <div class="copyright-wrap">
      <div class="container">
        <div class="copyright-content">
          <div class="left-copyright">
            © 2023 <a href="#">JobChain Pty Ltd</a>. All Right Reserved.
          </div>
          <div class="right-copyright">
            <ul class="powered-by">
              <li>
                <a href="https://www.datafluent.xyz/" target="_blank">
                  <img src="../../assets/dfa_logo_white.png" alt="dfa_logo">
                </a>
              </li>
              <li>
                Powered by
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--Bottom-->
    <!--    <div class="footer-bottom">-->
    <!--      <div class="auto-container">-->
    <!--        <div class="outer-box">-->
    <!--          <div class="copyright-text">© 2023 <a href="#">JobChain Pty Ltd</a>. All Right Reserved.</div>-->

    <!--&lt;!&ndash;          <div class="copyright-text">&ndash;&gt;-->
    <!--&lt;!&ndash;            <strong>Powered by </strong>&ndash;&gt;-->
    <!--&lt;!&ndash;            <a href="/">DataFluent Analytics</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->

    <!--          <ul class="powered-by">-->
    <!--            <li>-->
    <!--              <a href="https://www.datafluent.xyz/" target="_blank">-->
    <!--                <img src="../../assets/dfa_logo.svg" alt="dfa_logo">-->
    <!--              </a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          &lt;!&ndash; <div class="social-links">-->
    <!--            <a href="https://www.facebook.com/profile.php?id=100093448454141"><i class="fab fa-facebook-f"></i></a>-->
    <!--            <a href="https://twitter.com/home"><i class="fab fa-twitter"></i></a>-->
    <!--            <a href="#"><i class="fab fa-instagram"></i></a>-->
    <!--            <a href="https://www.linkedin.com/company/95059378/admin/"><i class="fab fa-linkedin-in"></i></a>-->
    <!--          </div> &ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="scroll-to-top scroll-to-target" data-target="html">
      <span class="fa fa-angle-up"></span>
    </div>
  </footer>

</template>


<script>
export default {
name:"AuthFooterView"
}
</script>

<style scoped>
/*.footer-columnx {border: 1px solid #eee}*/
.google-map {
  margin-right: 30px;
}

.jc-social-media {
  margin-top: 30px;
  color: white;
}

.jc-social-media h6 {
  margin-bottom: 15px;
  font-size: 14px;
}

.jc-social-media ul {
  display: flex;
  gap: 15px;
  align-items: center;
}

.jc-social-media ul li a {
  display: block;
  border: 1px solid #FFE2BF;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
  color: #FF7600;
  line-height: 24px;
  font-size: 14px;
  background-color: #FFE2BF;
}

.jc-social-media ul li a:hover {
  color: #B36200;
  /*background-color: transparent;*/
  transition: color ease-out 0.5s;
}

/* Copyright Section */
.copyright-wrap {
  background-color: black;
  color: white;
  padding: 15px 0;
  border-top: 1px solid #333;
}

.copyright-content {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  line-height: normal;
  align-items: center;
}

ul.powered-by {
  display: flex;
  gap: 5px;
  flex-direction: row-reverse;
  align-items: center;
}

ul.powered-by li {
  font-size: 10px;
}

ul.powered-by li a {
  display: block;
  font-size: 10px;
}

ul.powered-by li a img {
  padding: 5px;
  height: 30px;
}

.left-copyright {
  font-size: 12px;
}

.left-copyright a {
  color: #FF7600;
  font-weight: 300;
}
</style>