<template>
  <div>
    <!-- Banner Section New -->
    <section class="hero-wrap">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-6 mb-5">
            <div class="hero-left-content">
              <h1>
                Seize Your <br />
                <span class="text-highlight-primary">Admission</span> Opportunity
              </h1>
              <p>
                Simplifying Your Path to Higher Education, We're Here to Assist You at Every Step
              </p>

              <div class="btn-box">
                <a href="/edu-contact" class="btn btn-primary btn-lg">
                  Make Appointment <span class="fa fa-angle-double-right"></span>
                </a>
              </div>

            </div>
          </div>
          <div class="col-6 text-end">
            <div class="hero-right-content">
              <img src="/images/jobchain/hero-edu-img.svg" alt="Banner Img" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Edu Services -->
    <section id="services">
      <div class="container">
        <div class="services-content">
          <h2>Australian Education</h2>
          <p>
            Australia's education system consists of three main segments: Schools, Vocational Education and Training
            (VET), and Universities. Each segment offers unique qualities, benefits, and entry requirements for international students:
          </p>
          <div class="row">
            <div class="col-4 services-item mb-3">
              <div class="services-card">
                <div class="icon-wrap">
                  <span class="fa fa-graduation-cap"></span>
                </div>
                <h6>Schools</h6>
                <p>
                  Australian schools offer a high-quality education with a strong focus on academic and personal
                  development.
                  The school curriculum is designed to provide a well-rounded education, including core subjects and a
                  wide range of extracurricular activities.
                </p>
                <a href="/edu-school" target="_blank">Read more</a>
                <!--                <a href="/ano-find-match" target="_blank">Read more</a>-->
              </div>
            </div>
            <div class="col-4 services-item mb-3">
              <div class="services-card">
                <div class="icon-wrap">
                  <span class="fa fa-building"></span>
                </div>
                <h6>Vocational Education and Training (VET)</h6>
                <p>
                  VET institutions in Australia offer practical and skills-focused training in various industries, such
                  as hospitality, automotive, healthcare, and more.
                  Courses are designed to meet industry standards, ensuring students are job-ready upon graduation.
                </p>
                <a href="/edu-vet" target="_blank">Read more</a>
              </div>
            </div>
            <div class="col-4 services-item mb-3">
              <div class="services-card">
                <div class="icon-wrap">
                  <span class="fa fa-university"></span>
                </div>
                <h6>Universities</h6>
                <p>
                  Australian universities are known for their world-class education and research programs.
                  They offer a wide range of undergraduate and postgraduate courses in various fields, including STEM,
                  humanities, business, and the arts.
                </p>
                <a href="/edu-university" target="_blank">Read more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Edu Services -->
    <section id="office-location">
      <div class="container">
        <div class="services-content">
          <h2>Office Location</h2>
          <p>
            Find Your Nearest Office: Use our interactive map to pinpoint the office closest to you, and gain insights
            into the vibrant cities and communities where we're helping candidates find their ideal jobs.
          </p>
          <div class="row">
            <div class="col-3 services-item mb-3">
              <div class="location-card card-bg-other">
                <div class="icon-wrap">
                  <span class="fa fa-map-marker-alt"></span>
                </div>
                <h6>Australia Office</h6>
                <p>Suite: 103, Level 1,</p>
                <p>661 George Street, Haymarket, NSW 2000</p>
<!--                <p>ABN: 18 672 565 570</p>-->
                <p><strong>Email: </strong>admin@jobchainedu.com.au</p>
                <p><strong>Phone: </strong>+61 400 810 105</p>
                <p style="margin-top: -11px; margin-left: 45px;">+61 452 057 414</p>
                
<!--                <a href="/edu-school" target="_blank">Read more</a>-->
              </div>
            </div>
            <div class="col-3 services-item mb-3">
              <div class="location-card card-bg-other">
                <div class="icon-wrap">
                  <span class="fa fa-map-marker-alt"></span>
                </div>
                <h6>Thailand Office</h6>
                <p>Trendy Building, Floor G, No. 10/6 Sukhumvit Soi 13</p>
                <p>Watthana District, Bangkok 10110</p>
<!--                <p>ABN: 00 00 00 0</p>-->
                <p><strong>Email: </strong>sohail.imran@jobchainedu.com.au</p>
                <p><strong>Phone: </strong>+66 80 639 2313</p>
<!--                <a href="/edu-school" target="_blank">Read more</a>-->
              </div>
            </div>
            <div class="col-3 services-item mb-3">
              <div class="location-card card-bg-other">
                <div class="icon-wrap">
                  <span class="fa fa-map-marker-alt"></span>
                </div>
                <h6>Bangladesh Office</h6>
                <p>Suit 12A</p>
                <p>Navana Tower, 12th floor, 45 South Ave, C/A, Gulshan-1, Dhaka-1212</p>
<!--                <p>ABN: 00 00 00 0</p>-->
                <p><strong>Email: </strong>sajed.haq@jobchainedu.com.au</p>
                <p><strong>Phone: </strong>+880 1913 85 44 53</p>
                <p style="margin-top: -11px; margin-left: 45px;">+880 1707 80 01 96</p>
<!--                <a href="/edu-school" target="_blank">Read more</a>-->
              </div>
            </div>
            <div class="col-3 services-item mb-3">
              <div class="location-card card-bg-other">
                <div class="icon-wrap">
                  <span class="fa fa-map-marker-alt"></span>
                </div>
                <h6>UK Office</h6>
                <p>128-130</p>
                <p>The Grove, Startford, London, England, E15 1NS</p>
<!--                <p>ABN: 00 00 00 0</p>-->
                <p><strong>Email: </strong>support@hive4work.co.uk</p>
                <p><strong>Phone: </strong>+44 7495 587992</p>
<!--                <a href="/edu-school" target="_blank">Read more</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

<!--    <edu-offices-location></edu-offices-location>-->


  </div>
</template>

<script>
// import EduOfficesLocation from "@/components/education/EduOfficesLocation.vue";

export default {
  name: "EducationPage",
  // components: {EduOfficesLocation}
};
</script>

<style scoped>
.hero-wrap {
  background-color: #eee6;
}
.hero-left-content h1 {
  font-weight: 300;
  line-height: normal;
  z-index: 1;
}
.hero-left-content h1, .hero-left-content p {
  margin-bottom: 20px;
}
.text-highlight-primary {
  color: #FF7600;
  font-size: 40px;
  font-weight: 700;
  position: relative;
}

.text-highlight-primary:before {
  content: url(../../assets/doodle2.svg);
  position: absolute;
  left: 30px;
  right: 0;
  bottom: -25px;
  z-index: -1;
}

/* Service section */
.services-content {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding-top: 50px;
  padding-bottom: 30px;
}

.services-content h2 {
  color: black;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
}

.services-content p {
  margin-bottom: 30px;
}

.services-card {
  background-color: #f6f8fa;
  padding: 23px;
  border-radius: 10px;
  min-height: 320px;
}

.location-card {
  background-color: #f6f8fa;
  padding: 23px;
  border-radius: 10px;
  min-height: 320px;
}

.card-bg-other {
  background-color: #B362002B;
  /*background-color: #B362000D;*/
}

.services-card h6 {
  font-weight: 700;
  margin-bottom: 15px;
}

.location-card h6 {
  font-weight: 700;
  margin-bottom: 15px;
}

.services-card p {
  margin-bottom: 10px;
  
}

.location-card p {
  margin-bottom: 10px;
  font-size: 9pt;
  
}

.services-card a {
  font-weight: 700;
  color: #ff7600;
}

.icon-wrap {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #ff760033;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  color: #ff7600;
  border-radius: 5px;
  margin-bottom: 15px;
}
</style>
