<template>
  <header-view></header-view>
  <main>
    <router-view></router-view>
  </main>
  <edu-footer></edu-footer>
</template>

<script>
import HeaderView from "@/components/education/EduHeader.vue";
import EduFooter from "@/components/education/EduFooter.vue";

export default {
  name: "AdmissionLayoutView",
  components: {HeaderView, EduFooter}
};
</script>

