<template>
  <div class="page-wrapper dashboard">
    <!-- Header Span -->
    <!-- <span class="header-span"></span> -->

    <div
      style="text-align: center"
      v-if="msg"
      id="message_popup"
      class="alert alert-success"
    >
      <span
        ><i class="fa fa-check" style="font-size: 48px"></i><br />{{
          msg
        }}</span
      >
    </div>

    <div
      style="text-align: center"
      v-if="err_msg"
      id="message_popup"
      class="alert alert-warning"
    >
      <span
        ><i class="fa fa-exclamation-triangle" style="font-size: 48px"></i
        ><br />{{ err_msg }}</span
      >
    </div>

    <div
      style="text-align: center"
      v-if="delete_msg"
      id="delete_popup"
      class=" "
    >
      <h4>Sure to Detete ?</h4>

      <div>
        <div class="row mt-4">
          <div class="col-lg-6">
            <button @click.prevent="cancel_btn" class="theme-btn btn-style-one">
              Cancle
            </button>
          </div>
          <div class="col-lg-6">
            <button
              @click.prevent="confirm_btn"
              class="theme-btn btn-style-one"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <dash-header-view
      @get_message="message_show"
      @err_message="err_message_show"
    ></dash-header-view>
    <router-view
      :data-from-master="dataToPass"
      @delete_message="delete_show"
      @get_message="message_show"
      @err_message="err_message_show"
    ></router-view>
    <!-- <dash-footer-view></dash-footer-view> -->
  </div>

  <div class="user-sidebar" style="padding-top: 1%">
    <div style="text-align: center">
      <span :style="font"> {{ role_name }}</span>
    </div>
    <div class="sidebar-inner">
      <ul v-if="role === 'A'" class="navigation">
        <li v-bind:class="{ active: this.$route.name === 'admin-dashboard' }">
          <a href="/admin-dashboard"> <i class="la la-laptop"></i> Dashboard</a>
        </li>
        <li v-bind:class="{ active }">
          <a class="sub-btn1"
            ><i class="la la-bookmark-o"></i>Manage MSP<i
              class="fas fa-angle-right dropdown"
            ></i
          ></a>

          <div class="sub-menu1">
            <hr />

            <a @click="MspRecruiterRequestList" class="sub-item1"
              >MSP Requests List</a
            >
            <a @click="ContactedRequestList" class="sub-item1"
              >MSP Contacted List</a
            >
            <a @click="AdminManageMspUserView" class="sub-item1"
              >MSP User List</a
            >
            <a @click="AdminManageMspWithoutProfileListView" class="sub-item1"
              >Without Profile List</a
            >
            <a @click="AdminManageMspRecruiterProfileView" class="sub-item1"
              >MSP Recruiters</a
            >
            <a @click="Mspdemandcategory" class="sub-item1">Demand Category</a>
            <a @click="Mspdemandsubcategory" class="sub-item1">Sub-Category</a>
            <a @click="Mspdemandcard" class="sub-item1">Demand Card </a>
            <a @click="Mspcurrentdemand" class="sub-item1">Current Demand </a>
            <a @click="Mspexpireddemand" class="sub-item1">Expired Demand </a>
            <!-- <a @click="MspApplicantDemandList" class="sub-item1">
              Demand List</a
            > -->
            <!-- <a @click="MspRequestList" class="sub-item1"> Demand Request</a> -->
            <!-- <a @click="Invoice" class="sub-item1"> View Invoice</a>
              <a @click="GenerateInvoice" class="sub-item1"> Generate Invoice</a> -->
            <hr />
          </div>
        </li>

        <!-- <li
          v-bind:class="{
            active: this.$route.name === 'admin-manage-university-list',
          }"
        >
          <a href="/admin-manage-university-list"
            ><i class="la la-university"></i>University</a
          >
        </li> -->

        <!-- <li
          v-bind:class="{
            active: this.$route.name === 'admin-admission-Office-list',
          }"
        >
          <a href="/admin-admission-Office-list"
            ><i class="la la-home"></i>Admission Office</a
          >
        </li> -->

        <!-- <li
          v-bind:class="{
            active: this.$route.name === 'admin-admission-query-list',
          }"
        >
          <a href="/admin-admission-query-list"
            ><i class="la la-home"></i>Admission Query </a
          >
        </li> -->

        <li
          v-bind:class="{
            active: this.$route.name === 'admin-add-employer-type',
          }"
        >
          <a href="/admin-add-employer-type"
            ><i class="la la-file-invoice"></i>Employer Type</a
          >
        </li>
        <li
          v-bind:class="{
            active: this.$route.name === 'admin-dashboard-allemployer',
          }"
        >
          <a href="/admin-dashboard-allemployer"
            ><i class="la la-user"></i>Employers List</a
          >
        </li>
        <li
          v-bind:class="{
            active: this.$route.name === 'admin-dashboard-allapplicant',
          }"
        >
          <a href="/admin-dashboard-allapplicant"
            ><i class="la la-user-tie"></i>Applicants List</a
          >
        </li>
        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-managejob' ||
              this.$route.name === 'admin-add-job-category',
          }"
        >
          <a href="/admin-managejob"
            ><i class="la la-briefcase"></i>Manage Job</a
          >
        </li>

        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-manage-ad-board' ||
              this.$route.name === 'admin-ad-board',
          }"
        >
          <a href="/admin-manage-ad-board"
            ><i class="la la-ad"></i>Manage Ad Board</a
          >
        </li>
        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-manage-course' ||
              this.$route.name === 'course-category' ||
              this.$route.name === 'admin-add-course' ||
              this.$route.name === 'instructor-list',
          }"
        >
          <a href="/admin-manage-course"
            ><i class="la la-book"></i>Manage Course</a
          >
        </li>

        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-manage-carrer-advice' ||
              this.$route.name === 'career-category' ||
              this.$route.name === 'admin-add-carrer-advice',
          }"
        >
          <a href="/admin-manage-blogs"
            ><i class="la la-blog"></i>Manage Blogs</a
          >
        </li>

        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-manage-partner' ||
              this.$route.name === 'admin-create-partner',
          }"
        >
          <a href="/admin-manage-partner"
            ><i class="la la-star"></i>Manage Partner</a
          >
        </li>

        <li
          v-bind:class="{
            active:
              this.$route.name === 'rpo-request-list' ||
              this.$route.name === 'rpo-contacted-list',
          }"
        >
          <a href="/rpo-request-list"
            ><i class="la la-play-circle"></i>RPO Request List</a
          >
        </li>

        <li
          v-bind:class="{
            active:
              this.$route.name === 'admin-message-list' ||
              this.$route.name === 'admin-message-viewed-list',
          }"
        >
          <a href="/admin-message-list"
            ><i class="la la-envelope"></i>Message List</a
          >
        </li>

        <li
          v-bind:class="{
            active: this.$route.name === 'admin-newsletter-list',
          }"
        >
          <a href="/admin-newsletter-list"
            ><i class="la la-comments"></i>Newsletter List</a
          >
        </li>
      </ul>

      <ul v-if="role === 'R'" class="navigation">
        <li
          v-bind:class="{ active: this.$route.name === 'employee-dashboard' }"
        >
          <a href="/recruiter-dashboard">
            <i class="la la-laptop"></i> Dashboard</a
          >
        </li>
        <li
          v-bind:class="{
            active: this.$route.name === 'employer-profile-create',
          }"
        >
          <a href="/recruiter-profile"><i class="la la-user-tie"></i>Profile</a>
        </li>
        <li v-bind:class="{ active: this.$route.name === 'employer-postjob' }">
          <a v-if="action_capability === true" href="/recruiter-postjob"
            ><i class="la la-paper-plane"></i>Post a New Job</a
          >
          <a
            v-else
            style="color: red"
            href="/recruiter-profile"
            @click="action_cap"
            ><i class="la la-paper-plane"></i>Post a New Job</a
          >
        </li>
        <li
          v-bind:class="{ active: this.$route.name === 'employer-managejob' }"
        >
          <a v-if="action_capability === true" href="/recruiter-managejob"
            ><i class="la la-briefcase"></i> Manage Jobs
          </a>
          <a
            style="color: red"
            v-else
            href="/recruiter-profile"
            @click="action_cap"
            ><i class="la la-briefcase"></i> Manage Jobs
          </a>
        </li>
        <li v-bind:class="{ active: this.$route.name === 'home' }">
          <a href="/"><i class="la la-home"></i>Home</a>
        </li>

        <div style="margin-top: 50px">
          <img
            src="images/resource/manager.jpg"
            style="height: 200px; width: 100%"
          />
        </div>
      </ul>

      <ul v-if="role === 'M'" class="navigation">
        <div v-if="action_capability === true">
          <li
            v-bind:class="{
              active: this.$route.name === 'msp-recruiter-dashboard',
            }"
          >
            <a href="/msp-recruiter-dashboard">
              <i class="la la-laptop"></i> Dashboard</a
            >
          </li>

          <li
            v-bind:class="{
              active: this.$route.name === 'msp-recruiter-profile',
            }"
          >
            <a href="/msp-recruiter-profile"
              ><i class="la la-bookmark-o"></i>Profile</a
            >
          </li>

          <li
            v-bind:class="{
              active: this.$route.name === 'msp-recruiter-demand-card-list',
            }"
          >
            <a href="/msp-recruiter-demand-card-list"
              ><i class="la la-bookmark-o"></i>Demand Cards</a
            >
          </li>

          <li v-bind:class="{ active: this.$route.name === 'msplist' }">
            <a href="/msplist"><i class="la la-bookmark-o"></i>Demand List</a>
          </li>

          <li
            v-bind:class="{
              active: this.$route.name === 'expired-demand-list',
            }"
          >
            <a href="/expired-demand-list"
              ><i class="la la-bookmark-o"></i>Expired Demand</a
            >
          </li>
          <li v-bind:class="{ active: this.$route.name === 'home' }">
            <a href="/"><i class="la la-home"></i>Home</a>
          </li>
        </div>
        <div v-else>
          <span style="color: red">Please Contact With Admin</span>
        </div>
      </ul>

      <ul v-if="role === 'J'" class="navigation">
        <li
          v-bind:class="{ active: this.$route.name === 'candidate-dashboard' }"
        >
          <a href="/candidate-dashboard">
            <i class="la la-laptop"></i> Dashboard</a
          >
        </li>
        <li v-bind:class="{ active: this.$route.name === 'candidate-profile' }">
          <a href="/candidate-profile-single"
            ><i class="la la-user-tie"></i>My Resume</a
          >
        </li>
        <li v-bind:class="{ active: this.$route.name === 'candidate-findjob' }">
          <a v-if="action_capability === true" href="/candidate-findjob"
            ><i class="la la-user-alt"></i>Find jobs</a
          >
          <a
            v-else
            style="color: red"
            href="/candidate-profile"
            @click="action_cap"
            ><i class="la la-user-alt"></i>Find jobs</a
          >
        </li>
        <li
          v-bind:class="{ active: this.$route.name === 'candidate-appliedjob' }"
        >
          <a v-if="action_capability === true" href="/candidate-appliedjob"
            ><i class="la la-briefcase"></i> Applied Jobs
          </a>
          <a
            v-else
            style="color: red"
            href="/candidate-profile"
            @click="action_cap"
            ><i class="la la-briefcase"></i> Applied Jobs
          </a>
        </li>

        <li>
          <a v-if="action_capability === true" class="sub-btn1"
            ><i class="la la-bookmark-o"></i>Manage ODJ<i
              class="fas fa-angle-right dropdown"
            ></i
          ></a>

          <a
            v-else
            style="color: red"
            class="sub-btn1"
            @click="action_cap"
            href="/candidate-profile"
            ><i class="la la-bookmark-o"></i>Manage ODJ</a
          >

          <div class="sub-menu1">
            <a @click="CandidateOdjProfileSingleView" class="sub-item1"
              >Information</a
            >
            <a @click="CompletedAssignments" class="sub-item1">Assignments</a>
            <!-- <a @click="DoneAssignments" class="sub-item1">Done Assignments</a>
            <a @click="Attendence" class="sub-item1">Attendence</a> -->
          </div>
        </li>

        <li v-bind:class="{ active: this.$route.name === 'enrolled-courses' }">
          <a v-if="action_capability === true" href="/enrolled-courses"
            ><i class="la la-book"></i>Enrolled Courses
          </a>
          <a
            style="color: red"
            v-else
            href="/candidate-profile"
            @click="action_cap"
            ><i class="la la-book"></i>Enrolled Courses
          </a>
        </li>

        <li v-bind:class="{ active: this.$route.name === 'home' }">
          <a href="/"><i class="la la-home"></i>Home</a>
        </li>
        <!-- <div style="margin-top: 50px">
          <img
            src="images/resource/manager.jpg"
            style="height: 250px; width: 100%"
          />
        </div> -->
      </ul>
    </div>
  </div>
</template>

<script>
import DashHeaderView from "@/components/dashboard/DashHeaderView.vue";
import vcookie from "vue-cookie";
// import DashFooterView from '@/components/dashboard/DashFooterView.vue'
export default {
  name: "DashboardMasterView",
  components: { DashHeaderView },
  data() {
    return {
      msg: "",
      delete_msg: "",
      is_delete: false,
      dataToPass: false,
      role: "",
      role_name: "",
      font: "",
      active: "",
      action_capability: "",
    };
  },

  provide() {
    return {
      delete_status: "",
    };
  },
  methods: {
    getrole() {
      const user = JSON.parse(vcookie.get("user"));
      this.role = user.user.role;
      this.action_capability = user.user.action_capability;
      if (user.user.role == "A") {
        this.role_name = "Admin Portal";
        this.font = "color: #ff7600; font-size:22pt";
      } else if (user.user.role == "R") {
        this.role_name = "Recruiter Portal";
        this.font = "color: #ff7600; font-size:20pt";
      } else if (user.user.role == "J") {
        this.role_name = "Applicent Portal";
        this.font = "color: #ff7600; font-size:20pt";
      } else if (user.user.role == "M") {
        this.role_name = "MSP Recruiter Portal";
        this.font = "color: #ff7600; font-size:18pt";
      }
    },
    message_show(message) {
      this.msg = message;

      this.message_hide();
    },
    err_message_show(message) {
      this.err_msg = message;

      this.message_hide();
    },

    action_cap() {
      alert("Please Create your Profile ");
    },

    confirm_btn() {
      if (this.dataToPass == false) {
        this.dataToPass = true;
      } else {
        this.dataToPass = false;
      }

      document.getElementById("delete_popup").style.display = "none";
    },

    cancel_btn() {
      document.getElementById("delete_popup").style.display = "none";
    },

    delete_show(message) {
      this.delete_msg = message;

      this.delete_popup_show();

      if (document.getElementById("delete_popup")) {
        const box = document.getElementById("delete_popup");
        box.style.display = "block";
      }
    },

    delete_popup_show() {},

    message_hide() {
      setTimeout(() => {
        document.getElementById("message_popup").style.display = "none";
      }, 2000);

      if (document.getElementById("message_popup")) {
        const box = document.getElementById("message_popup");
        box.style.display = "block";
      }
    },

    MspRecruiterList() {
      this.$router.push({
        name: "msp-recruiter-list",
      });
      this.active = "active";
    },

    MspRecruiterRequestList() {
      this.$router.push({
        name: "msp-recruiter-request-list",
      });
      this.active = "active";
    },

    ContactedRequestList() {
      this.$router.push({
        name: "contacted-msp-request-list",
      });
      this.active = "active";
    },
    AdminManageMspUserView() {
      this.$router.push({
        name: "manage-msp-user",
      });
      this.active = "active";
    },
    AdminManageMspWithoutProfileListView() {
      this.$router.push({
        name: "msp-user-without-profile-list",
      });
      this.active = "active";
    },
    AdminManageMspRecruiterProfileView() {
      this.$router.push({
        name: "msp-recruiter-profile-list",
      });
      this.active = "active";
    },

    MspApplicantDemandList() {
      this.$router.push({
        name: "msp-applicant-demand-list",
      });
      this.active = "active";
    },

    MspRequestList() {
      this.$router.push({
        name: "msp-request-list",
      });
      this.active = "active";
    },

    Mspdemandcategory() {
      this.$router.push({
        name: "msp-add-demand-category",
      });
      this.active = "active";
    },

    Mspdemandsubcategory() {
      this.$router.push({
        name: "msp-demand-sub-category",
      });
      this.active = "active";
    },

    Mspdemandcard() {
      this.$router.push({
        name: "msp-demand-card-list",
      });
      this.active = "active";
    },

    Mspcurrentdemand() {
      this.$router.push({
        name: "msp-current-demand-view",
      });
      this.active = "active";
    },

    Mspexpireddemand() {
      this.$router.push({
        name: "msp-expired-demand-list",
      });
      this.active = "active";
    },

    Invoice() {
      this.$router.push({
        name: "invoice",
      });
    },

    GenerateInvoice() {
      this.$router.push({
        name: "generate-invoice",
      });
    },

    //Manage odj

    CandidateOdjProfileSingleView() {
      this.$router.push({
        name: "odj-profile-single",
      });
    },

    CompletedAssignments() {
      this.$router.push({
        name: "candidate-odj-Completed-assignment",
      });
    },

    DoneAssignments() {
      this.$router.push({
        name: "candidate-odj-done-assignment",
      });
    },

    Attendence() {
      this.$router.push({
        name: "candidate-odj-attendence",
      });
    },
  },
  mounted: function () {
    this.getrole();
  },
};
</script>

<style>
.sidebar-inner .dropdown {
  position: absolute;
  right: -40px;
  margin: 8px;
  transition: 0.3s ease;
}

.sidebar-inner .sub-menu1 {
  background: #fff;
  display: none;
}

.sidebar-inner .sub-menu1 a {
  padding-left: 80px;
}

.sidebar-inner .sub-menu1 a:hover {
  background-color: rgba(25, 103, 210, 0.1);
}

.sidebar-inner .sub-item1 {
  font-size: 14px;
  margin-left: 35px;
}

.rotate {
  transform: rotate(90deg);
}
</style>
