<template>
  <section class="privacy-policy">
    <div class="container">
      <div class="tc-box">
        <h4>Privacy Policy (Education)</h4>
<!--        <p>International Student Recruitment Agency</p>-->
        <div class="effective-date">
          <i>Effective Date: [06 NOV 2023]</i>
        </div>

        <div class="ts-content">
          <div class="title">1. Introduction:</div>
          <p class="para">
            Job Chain Education Pty Ltd (hereafter referred to as "we," "us," "our") is committed to protecting the
            privacy and personal information of individuals, including prospective and enrolled students, clients, and
            website visitors. This Privacy Policy outlines how we collect, use, disclose, and protect your personal
            information in accordance with Australian privacy laws.
          </p>

          <div class="title">2. Information We Collect:</div>
          <p class="para">
            2.1. Personal Information: We may collect personal information, including but not limited to, names, contact
            details, nationality, date of birth, educational history, and other details relevant to providing our
            services.
          </p>
          <p class="para">
            2.2. Sensitive Information: We may collect sensitive information, such as passport details, visa
            information, medical information, and financial information for the purpose of assisting with student visa
            applications and related services.
          </p>
          <p class="para">
            2.3. Website Information: We may collect non-identifying information related to your use of our website,
            including IP addresses, browser information, and cookies. Please refer to our Cookies Policy for more
            information.
          </p>

          <div class="title">3. How We Collect Information:</div>
          <p class="para">
            We may collect information through various means, including: <br>
             - Direct interactions with you during consultations, applications, and communications. <br>
             - Information provided via forms on our website.<br>
             - Correspondence through email, phone, or other means.<br>
             - Information received from educational institutions and authorities.<br>
          </p>

          <div class="title">4. Use of Information:</div>
          <p class="para">
            We may use your personal information for the following purposes:<br>
            4.1. To provide education and recruitment services to prospective and enrolled students.<br>
            4.2. To assist with student visa applications and immigration-related services.<br>
            4.3. To communicate with you regarding our services, updates, and events.<br>
            4.4. To improve and optimize our website, services, and customer experience.<br>
          </p>

          <div class="title">5. Disclosure of Information:</div>
          <p class="para">
            We may disclose your information to the following parties:<br>
            5.1. Educational Institutions: To facilitate admissions and enrollments.<br>
            5.2. Government Authorities: For student visa applications and compliance with immigration laws.<br>
            5.3. Service Providers: To help us deliver our services, such as IT support, marketing, and communications.<br>
          </p>

          <div class="title">6. Security and Protection:</div>
          <p class="para">
            We take reasonable measures to protect your personal information from unauthorized access, disclosure,
            alteration, and destruction. We regularly review and enhance our security practices to ensure the protection
            of your data.
          </p>

          <div class="title">7. Access and Correction:</div>
          <p class="para">
            You have the right to access, correct, or request the deletion of your personal information. If you wish to
            exercise these rights, please contact us using the contact information provided below.
          </p>

          <div class="title">8. Cookies Policy:</div>
          <p class="para">
            Please refer to our Cookies Policy for details on how we use cookies and similar technologies on our website.
          </p>

          <div class="title">9. Contact Us:</div>
          <p class="para">
            If you have questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at:
            <br>
            Job Chain Education Pty Ltd <br>
            Address: Suite: 308, 379 Pitt Street, Sydney, NW 2000. <br>
            Web address: <a href="/edu" target="_blank">www.jobchainedu.com.au</a>
          </p>

          <div class="title">10. Updates to Privacy Policy:</div>
          <p class="para">
            We may update this Privacy Policy from time to time to reflect changes in our practices and legal
            requirements. Any updates will be posted on our website, and we encourage you to review this policy
            periodically.
          </p>






        </div><!-- End -->

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EduPrivacy",
};
</script>

<style scoped>
/* Add ol element style */
ol {
  counter-reset: list;
  color: #696969;
}

ol > li {
  list-style: none;
}

ol > li:before {
  content: counter(list, lower-alpha) ". ";
  counter-increment: list;
}

/* T and C design */
.tc-box {
  padding: 50px 0;
  max-width: 800px;
  margin: 0 auto;
}

.effective-date {
  margin-bottom: 30px;
}

.ts-content .para {
  margin-bottom: 30px;
}

.ts-content .title {
  font-weight: 500;
  margin-bottom: 10px;
}

</style>
