<template>
  <section class="terms-and-condition">
    <div class="container">
      <div class="tc-box">
        <h4>Terms and Conditions (Education)</h4>
        <!--        <p>International Student Recruitment Agency</p>-->
        <div class="effective-date">
          <i>Effective Date: [06 NOV 2023]</i>
        </div>

        <div class="ts-content">
          <div class="title">1. Definitions:</div>
          <p class="para">
            1.1. Agent: Refers to "Job Chain Education Pty Ltd," an Australian education agent based in Sydney,
            responsible for recruiting international students for educational institutions in Australia.
          </p>

          <p class="para">
            1.2. Student: Refers to the international students who engage the services of Job Chain Education Pty Ltd to
            apply for and enroll in Australian educational institutions.
          </p>
          <p class="para">
            1.3. Educational Institutions: Refers to the Australian educational institutions (universities, colleges,
            schools, etc.) that Job Chain Education Pty Ltd represents or recommends to students.
          </p>

          <div class="title">2. Agent's Services:</div>
          <p class="para">
            2.1. Job Chain Education Pty Ltd shall provide the following services to the Student: <br>

            a. Advising on suitable educational programs and institutions in Australia. b. Assisting with the
            application process, including documentation preparation. c. Providing guidance on student visa application
            and related matters. d. Offering support with pre-arrival services. e. Facilitating communication between
            the Student and the Educational Institutions.
          </p>
          <p class="para">
            2.2. Job Chain Education Pty Ltd shall exercise reasonable care, skill, and diligence in carrying out the
            services on behalf of the Student.
          </p>

          <div class="title">3. Student Obligations:</div>
          <p class="para">
            3.1. The Student shall provide accurate and complete information to Job Chain Education Pty Ltd for the
            purpose of applying to educational institutions in Australia.
          </p>
          <p class="para">
            3.2. The Student shall meet the admission requirements set by the chosen educational institution, including
            English language proficiency and academic qualifications.
          </p>
          <p class="para">
            3.3. The Student is responsible for securing the necessary finances, obtaining a valid student visa, and
            ensuring their compliance with Australian immigration laws and regulations.
          </p>

          <div class="title">4. Fees and Payments:</div>
          <p class="para">
            4.1. The fees for Job Chain Education Pty Ltd's services will be outlined in a separate agreement between
            Job Chain Education Pty Ltd and the Student. Payment terms and methods will also be stipulated in the
            agreement.
          </p>

          <div class="title">5. Privacy and Confidentiality:</div>
          <p class="para">
            5.1. Job Chain Education Pty Ltd shall handle the Student's personal information in accordance with Australian privacy laws and regulations.
          </p>
          <p class="para">
            5.2. Job Chain Education Pty Ltd and the Student shall keep all information provided during the recruitment
            process confidential, unless required by law.
          </p>

          <div class="title">6. Limitation of Liability:</div>
          <p class="para">
            6.1. Job Chain Education Pty Ltd is not responsible for the admission decisions made by the educational
            institutions.
          </p>
          <p class="para">
            6.2. Job Chain Education Pty Ltd is not liable for any delays or issues related to the student visa
            application process.
          </p>

          <div class="title">7. Termination of Services:</div>
          <p class="para">
            7.1. Job Chain Education Pty Ltd or the Student may terminate the services by providing written notice to
            the other party. Any fees or refunds due upon termination will be outlined in the agreement.
          </p>

          <div class="title">8. Dispute Resolution:</div>
          <p class="para">
            8.1. Any disputes arising between Job Chain Education Pty Ltd and the Student shall be subject to mediation
            or arbitration in accordance with Australian laws.
          </p>

          <div class="title">9. Governing Law:</div>
          <p class="para">
            9.1. These terms and conditions shall be governed by and construed in accordance with the laws of Australia.
          </p>

          <div class="title">10. Amendment and Updates:</div>
          <p class="para">
            10.1. Job Chain Education Pty Ltd may amend or update these terms and conditions, and the Student shall be
            notified of any changes in writing.
          </p>

        </div><!-- End -->

      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "EduTerms",
};
</script>

<style scoped>
  /* Add ol element style */
  ol {
    counter-reset: list;
    color: #696969;
  }

  ol > li {
    list-style: none;
  }

  ol > li:before {
    content: counter(list, lower-alpha) ". ";
    counter-increment: list;
  }

  /* T and C design */
  .tc-box {
    padding: 50px 0;
    max-width: 800px;
    margin: 0 auto;
  }

  .effective-date {
    margin-bottom: 30px;
  }

  .ts-content .para {
    margin-bottom: 30px;
  }

  .ts-content .title {
    font-weight: 500;
    margin-bottom: 10px;
  }

</style>
