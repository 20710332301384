<template>
  <div class="course-detail-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="jc-flex-container">
          <div class="jc-flex-list">
            <div class="jc-flex-item">
              <h2>Admission Details</h2>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="course-detail">
      <div class="container">
        <div class="row justify-content-between g-5">
          <div class="col-sm-8 course-left-bar ">
            <div class="left-content-wrap">

<!--              <div class="img-wrap">-->
<!--                <img class="rounded" src="../../assets/top-b2-bg.png" alt="">-->
<!--              </div>-->

              <div class="overview my-3 shadow">
                <div class="para">
                  <!-- Contact Form -->
                  <div class="contact-form default-form p-4">
                    <div class="header mb-5">
                      <div class="img-wrap">
                        <img
                            :src="ano_uni_view.logo"
                            alt="logo"
                        />
                        <!--                  <img src="../../assets/logo.png" alt="logo">-->
                      </div>
                      <div class="content">
                        <h6 class="job-title">{{ ano_uni_view.name }}</h6>
                        <p>
                          <i class="fa fa-map-marker-alt"></i> {{ ano_uni_view.city }}, {{ ano_uni_view.country }}
                          | <i class="fa fa-link"></i> <a :href="ano_uni_view.website" target="_blank">Website link</a>

                        </p>
                      </div>
                    </div>

                    <h5>Request for more information!</h5>
                    <!--Contact Form-->
                    <form @submit.prevent="add_admission_Query">
                      <div class="row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          <div class="response"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Full Name</label>
                          <input
                              v-model="full_name"
                              type="text"
                              class="username"
                              placeholder="Full Name*"
                              
                              @input="clearError('full_name')"
                          />
                          <div id="full_name" class="text-danger error"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Nearby Branch</label>
                          <select  class="form-select"
                          v-model="office" 
                          @input="clearError('office')"
                          >
                            <option disabled :value="null">
                            Select Nearby Branch
                          </option>
                            <option 
                            v-for="office in offices"
                            :key="office.id"
                            :value="office.id"
                          >
                            {{ office.city }}, {{ office.country }}
                          </option>
                            <!-- <option selected>Select Branch</option>
                            <option value="1">Dhaka, Bangladesh</option>
                            <option value="2">KL, Malaysia</option> -->
                          </select>

                          <div id="office" class="text-danger error"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>University</label>
                          <select  class="form-select"
                          v-model="university" 
                          @input="clearError('university')"
                          >
                            <option disabled :value="null">
                            Select university
                          </option>
                            <option 
                            v-for="uni in unidata"
                            :key="uni.id"
                            :value="uni.id"
                          >
                            {{ uni.name }}
                          </option>
                            <!-- <option selected>Select Branch</option>
                            <option value="1">Dhaka, Bangladesh</option>
                            <option value="2">KL, Malaysia</option> -->
                          </select>

                          <div id="university" class="text-danger error"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Phone(+country code)</label>
                          <input
                              v-model="phone"
                              type="text"
                              class="username"
                              placeholder="Phone*"
                              @input="clearError('phone')"
                          />
                          <div id="phone" class="text-danger error"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Email</label>
                          <input
                              v-model="email"
                              type="email"
                              class="email"
                              placeholder="Email*"
                              @input="clearError('email')"
                          />
                          <div id="email" class="text-danger error"></div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>City</label>
                          <input
                              v-model="city"
                              type="text"
                              class="username"
                              placeholder="City*"
                              @input="clearError('city')"
                          />
                          <div id="city" class="text-danger error"></div>
                        </div>

<!--                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">-->
<!--                          <label>Country</label>-->
<!--                          <input-->
<!--                              type="email"-->
<!--                              class="email"-->
<!--                              placeholder="Country*"-->
<!--                              required-->
<!--                          />-->
<!--                        </div>-->

                        <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                          <label>Country</label>
                          <select
                           v-model="country" 
                           @input="clearError('country')"
                           class="form-select">
                            <option disabled value="null">Select country</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Australia">Australia</option>
                          </select>
                          <div id="country" class="text-danger error"></div>
                        </div>



                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button class="btn btn-primary"
                                  type="submit" id="submit"
                                  name="submit-form">Send Massage</button>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>


            </div>
          </div>
          <div class="col-sm-4 course-right-bar ">
<!--            <div class="right-content-wrap">-->
<!--              <div class="card p-3">-->
<!--                <ul class="list-col-type mb-3">-->
<!--                  <li>-->
<!--                    <strong>Item price: </strong>-->
<!--                    <span>2000$</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Study method: </strong>-->
<!--                    <span>Online</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Duration: </strong>-->
<!--                    <span>4 years</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Qualification: </strong>-->
<!--                    <span>SSC</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>This Course Cover: </strong>-->
<!--                    <span>Plabon Asad</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>You Can Learn: </strong>-->
<!--                    <span>JavaScript</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Course For: </strong>-->
<!--                    <span>Self Development</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Career Path: </strong>-->
<!--                    <span>Causual Job</span>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <strong>Updated: </strong>-->
<!--                    <span>12-11-2023</span>-->
<!--                  </li>-->
<!--                </ul>-->

<!--                <div class="d-grid">-->
<!--                  <button class="btn btn-primary"-->
<!--                          type="button">Apply Now</button>-->
<!--                </div>-->


<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "EduProviderDetail",

  data() {
    return {
      offices: [],
      unidata:{},
      ano_uni_view: {},

      university: null,
      office: null,
      country: null,

      //Pagenation
      // current_page: 1,
      // next_page: "",
      // next: "",
      // previous: "",
      // pages: 1,
      // total_item: "",
      // all_data: {},
    };
  },

  methods:{

    get_office() {
      axios
          .get("anonymous/admission_office/")
          .then((res) => {
            console.log('full res: ', res.data);
            this.offices = res.data;

          })
          .catch((error) => {
            console.log('error.response', error.response);
          });
    },

    get_university() {
      axios
          .get("anonymous/university/")
          .then((res) => {
            console.log('full res: ', res.data.results);
            this.unidata = res.data.results;

          })
          .catch((error) => {
            console.log('error.response', error.response);
          });
    },

    get_university_details(uuid) {
      axios
        .get("anonymous/university/" + uuid + "/")
        .then((res) => {
          this.ano_uni_view = res.data;
         
          
          
          

          // var loader = document.getElementById("loader-small");
          //     loader.style.display = "none";

          if (res.status == 200) {
            console.log("done");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    nullcheck(element) {
      $("#" + element).html("This field is required");
      window.scrollTo(0, $("#" + element).offset().top - 200);
    },

    clearError(element) {
      $("#" + element).html("");
    },


    add_admission_Query() {
      if (this.full_name== null || this.full_name == "") {
        this.nullcheck("full_name");
      } else if (this.office == null || this.office == "") {
        this.nullcheck("office");
      } else if (this.university == null || this.university == "") {
        this.nullcheck("university");
      } else if (this.phone == null || this.phone == "") {
        this.nullcheck("phone");
      } else if (this.email == null || this.email == "") {
        this.nullcheck("email");
      } else if (this.city == null || this.city == "") {
        this.nullcheck("city");
      } else if (this.country == null || this.country == "") {
        this.nullcheck("country");
      }  
      else {
        axios
          .post("anonymous/admission_query/", {
            full_name: this.full_name,
            office: this.office,
            university: this.university,
            phone: this.phone,
            email: this.email,
            city: this.city,
            country: this.country,

          })
          .then((res) => {
            if (res.status == 201) {
              this.full_name =null;
              this.office = null;
              this.university = null;
              this.phone = null;
              this.email = null;
              this.city = null;
              this.country = null;

              console.log('admission response: ', res);

              this.$emit("get_message", "Request Successful");
            }
          })
          .catch((error) => {
            console.log(error.response);

            this.$emit("get_message", " Your Request already exists");
          });
      }
    },


  },

  mounted: function () {
    this.get_office();
    this.get_university();
    // this.get_university_details();
    var uuid = this.$route.query.uuid;
    // var loader = document.getElementById("loader-small");
    //   loader.style.display = "block";

    if (uuid != null) {
      this.get_university_details(uuid);
      window.scrollTo(0, 0);
    }
  },

};
</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

/* Title banner (custom center) */
.jc-flex-container {
  height: 150px;
  max-width: 500px;
}

.jc-flex-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /*background-color: lightpink;*/
}

.jc-flex-list .jc-flex-item h2{
  font-size: 30px;
  font-weight: 200;
}
/* Title banner (custom center) end */

.course-detail {
  padding-top: 50px;
  padding-bottom: 30px;
  max-width: 900px;
  margin: 0 auto;
}

ul.list-type {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

ul.list-type li {
  margin-right: 15px;
}

ul.list-type li a {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #777;
  font-weight: 200;
  font-size: 12px;
}

ul.list-type li a .text {
  font-size: 12px;
}

.left-content-wrap h3 {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: normal;
}

.img-wrap {
  width: auto;
  height: auto;
  overflow: hidden;
  margin-bottom: 0px;
}

.img-wrap img {
  width: 80px;
  height: auto;
  padding: 0px;
  margin-right: 10px;
}

ul.list-col-type li {
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: normal;
  gap: 15px;
}

/* Img list design */
.header {
  display: flex;
  align-items: center;
  gap: 15px;
  /*background-color: #FF760033;*/
  margin: -15px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #eee;
}
.header .img-wrap img {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}
.header .content p {
  margin: 0;
  font-size: 12px;
  /*color: #FF7600;*/
  font-weight: 500;
}

</style>
