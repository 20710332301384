<script>
import axios from "axios";
export default {
  name: "EduProvider",

  data() {
    return {
      ano_uni: [],
      offices: [],

      //Pagenation
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",
      all_data: {},
      key: "",
    };
  },

  methods: {
    openuni(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "EduProviderDetail", query: { uuid: uuid } });
      }
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    get_university() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";
      axios
        .get("anonymous/university/")
        .then((res) => {
          if (res == undefined) {
            loader.style.display = "none";
            document.getElementById("nodata").innerHTML =
              "Sorry! no relevent data available";
          }
          if (res.status == 200) {
            loader.style.display = "none";
            this.ano_uni = res.data.results;
            this.total_item = res.data.count;
            this.all_data = res.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 20);
            }

            console.log(res.status);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    get_office() {
      axios
        .get("anonymous/admission_office/")
        .then((res) => {
          if (res.status == 200) {
            this.offices = res.data;
            this.total_item = res.data.count;
            this.all_data = res.data;
            if (this.total_item > 0) {
              this.pages = Math.ceil(this.total_item / 20);
            }

            console.log("data: ", this.ano_uni);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    page(page_number) {
      axios
        .get("anonymous/university/?page=" + page_number)
        .then((res) => {
          if (res.status == 200) {
            this.current_page = page_number;

            this.ano_uni = res.data.results;
            this.all_data = res.data;
            window.scrollTo(0, 0);

            console.log("Data received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    get_uniss(key) {
      axios
        .get(
          "anonymous/university/",
          {
            params: {
              search: key,
            },
          },
          { ignore: "1" }
        )
        .then((res) => {
          if (res.status == 200) {
            this.ano_uni = res.data.results;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    showuni(text) {
      if (text != null) {
        this.get_uniss(text);
      }
    },

    goTo(url = "") {
      this.$router.push({
        name: url,
      });
    },
  },

  mounted: function () {
    this.get_university();
    this.get_office();
  },
};
</script>

<template>
  <div class="admission-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="jc-flex-container">
          <div class="jc-flex-list">
            <div class="jc-flex-item">
              <h2 class="mb-2">Admission</h2>
            </div>
            <div class="jc-flex-item">
              <!--              <div class="input-group mb-3 search-with-btn">-->
              <!--                <input type="text" class="form-control search-input "-->
              <!--                       placeholder="Search by University/College"-->
              <!--                       style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important">-->
              <!--                <div class="input-group-append">-->
              <!--                  <button class="btn btn-primary btn-search"-->
              <!--                          style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important"-->
              <!--                          type="button">Search</button>-->
              <!--                </div>-->
              <!--              </div>-->

              <form>
                <div class="input-group group-search mb-3">
                  <input
                    @input="showuni($event.target.value)"
                    id="search-input-first"
                    type="text"
                    v-model="key"
                    class="search form-control"
                    placeholder="Search by University/City"
                  />
                  <!-- <input
                      id="search-input-mid"
                      type="text"
                      class="location form-control"
                      placeholder="Location"
                  /> -->
                  <button
                    @click.prevent="showuni(key)"
                    class="btn btn-primary"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="fj-content">
        <div class="row">
          <div class="col-9 mb-4">
            <div v-if="ano_uni.length > 0">
              <div class="row">
                <div
                  v-for="uni in ano_uni"
                  :key="uni.id"
                  class="col-6 feared-job-item mb-3"
                >
                  <div class="fj-card shadow-sm">
                    <div @click="openuni(uni.uuid)" class="header">
                      <div class="img-wrap">
                        <img :src="uni.logo" alt="university_logo" />
                      </div>
                      <div class="content">
                        <h6 class="job-title">{{ uni.name }}</h6>
                        <p>
                          <i class="fa fa-map-marker-alt"></i> {{ uni.city }},
                          {{ uni.country }} <i class="fa fa-link"></i>
                          <a :href="uni.website" target="_blank">
                            Website link
                          </a>
                        </p>
                      </div>
                    </div>

                    <!--                  <div class="body">-->
                    <!--                    <ul class="job-info-list">-->
                    <!--                      <li>-->
                    <!--                        <b>Email: </b>-->
                    <!--                      </li>-->
                    <!--                      <li>-->
                    <!--                        asad@test.com-->
                    <!--                      </li>-->
                    <!--                    </ul>-->
                    <!--                  </div>-->
                  </div>
                </div>

                <!-- <div @click="goTo('admission-detail')" class="col-6 feared-job-item mb-3">
                <div class="fj-card shadow-sm">
                  <div class="header">
                    <div class="img-wrap">
                      <img src="../../assets/logo.png" alt="logo">
                    </div>
                    <div class="content">
                      <h6 class="job-title">Hamburger University</h6>
                      <p><a href="http://www.northsouth.edu/">See link</a></p>
                    </div>
                  </div>

                  <div class="body">
                    <ul class="job-info-list">
                      <li>
                        <b>City:</b> Dhaka
                      </li>
                      <li><b>Country:</b> Bangladesh</li>
                    </ul>

                  </div>
                </div>
              </div> -->

                <nav class="ls-pagination mt-3">
                  <ul>
                    <li v-if="all_data.previous != null" class="prev">
                      <a @click.prevent="page(current_page - 1)"
                        ><i class="fa fa-arrow-left"></i
                      ></a>
                    </li>
                    <li v-for="i in pages" :key="i">
                      <a
                        :class="{ 'current-page': current_page == i }"
                        @click.prevent="page(i)"
                        >{{ i }}</a
                      >
                    </li>

                    <li v-if="all_data.next != null" class="next">
                      <a @click.prevent="page(current_page + 1)"
                        ><i class="fa fa-arrow-right"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <p id="nodata" style="color: red"></p>

            <!-- Pagination -->
            <!--            <nav class="ls-pagination mb-3">-->
            <!--              <ul>-->
            <!--                <li class="prev">-->
            <!--                  <a><i class="fa fa-arrow-left"></i></a>-->
            <!--                </li>-->
            <!--                <li>-->
            <!--                  <a class="current-page">1</a>-->
            <!--                </li>-->
            <!--                <li>-->
            <!--                  <a class="">2</a>-->
            <!--                </li>-->

            <!--                <li class="next">-->
            <!--                  <a>-->
            <!--                    <i class="fa fa-arrow-right"></i>-->
            <!--                  </a>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </nav>-->
          </div>
          <div id="loader-small" style="text-align: center">
            <p>PLease wait</p>
            <span class="loading__dot_small"></span>
            <span class="loading__dot_small"></span>
            <span class="loading__dot_small"></span>
          </div>
          <div class="col-3 mb-4">
            <div
              class="address-list card p-3 mb-3"
              v-for="office in offices"
              :key="office.id"
            >
              <h6 class="bg-header rounded p-2">{{ office.name }}</h6>
              <ul class="list-col-type mb-3">
                <li>
                  <strong>Email: </strong>
                  <span class="link-primary">{{ office.email }}</span>
                </li>
                <li>
                  <strong>Phone: </strong>
                  <span class="link-primary">{{ office.contact_no }}</span>
                </li>
                <!--                <li>-->
                <!--                  <strong>Location: </strong>-->
                <!--                  <span>-->
                <!--                    {{office.address}}-->
                <!--                  </span>-->
                <!--                </li>-->
              </ul>

              <!-- <h6 class="bg-header rounded p-2">Address 2</h6>
              <ul class="list-col-type mb-4">
                <li class="p-2">
                                   <strong>Name: </strong>
                  <span>
                    Working...
                  </span>
                </li>
              </ul> -->

              <!--              <h6 class="bg-header rounded p-2">Address 2</h6>-->
              <!--              <ul class="list-col-type mb-5">-->
              <!--                <li>-->
              <!--                  <strong>Name: </strong>-->
              <!--                  <span>Asad</span>-->
              <!--                </li>-->
              <!--                <li>-->
              <!--                  <strong>Name: </strong>-->
              <!--                  <span>Asad</span>-->
              <!--                </li>-->
              <!--                <li>-->
              <!--                  <strong>Name: </strong>-->
              <!--                  <span>Asad</span>-->
              <!--                </li>-->
              <!--              </ul>-->

              <!--              <div class="d-grid">-->
              <!--                  <button @click="goTo('contact')" class="btn btn-primary" type="button" >Contact Us</button>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Admission section */
.bg-header {
  background-color: rgba(255, 118, 0, 0.2);
  color: rgba(255, 118, 0, 1);
  font-size: 15px;
}
.container-sm {
  padding-top: 50px;
  padding-bottom: 30px;
  max-width: 900px;
  margin: 0 auto;
}

ul.list-col-type li {
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: normal;
  gap: 15px;
}

/* Admission section End */

/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #ff7600, rgba(255, 198, 128, 0.5)),
    url("../../assets/light.jpg");
  background-color: #ff7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

/* Title banner (custom center) */
.jc-flex-container {
  height: 150px;
  max-width: 700px;
}

.jc-flex-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /*background-color: lightpink;*/
}

.jc-flex-list .jc-flex-item h2 {
  font-size: 30px;
  font-weight: 200;
}
/* Title banner (custom center) end */

.search-with-btn input.search-input {
  border: 1px solid transparent;
}

.group-search {
  border: 1px solid white;
  border-radius: 4px;
  outline: 1px solid;
}

/* Admission section design */
.fj-content {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding-top: 50px;
  padding-bottom: 30px;
}

.fj-content p {
  margin-bottom: 30px;
}
.fj-content h2 {
  color: black;
}

.fj-card {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.fj-card .header {
  display: flex;
  align-items: center;
  gap: 15px;
  /*background-color: #FF760033;*/
  margin: -15px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #eee;
}

.fj-card .header .img-wrap img {
  width: 60px;
  padding: 5px;
  height: auto;
  border-radius: 5px;
  border: 1px solid #eee;
}

.fj-card:hover {
  transition: border-color ease-in 0.6s;
  cursor: pointer;
  border-color: #ff7600;
}

.fj-card .header .content p {
  margin: 0;
  font-size: 12px;
  /*color: #FF7600;*/
  font-weight: 500;
}

.fj-card .body {
  padding-top: 25px;
}

.fj-card .body ul.job-info-list {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
</style>
