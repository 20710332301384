<template>
  <div class="need-help-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="tb-content">
          <h2>Education University</h2>
          <!--          <p></p>-->
        </div>

      </div>
    </div>

    <div class="container">
      <!--      <no-data></no-data>-->
      <div class="tc-box">
        <div class="content-wrap">
          <p class="mb-3">
            Australia's education system consists of three main segments: Schools, Vocational Education and Training
            (VET), and Universities. Each segment offers unique qualities, benefits, and entry requirements for
            international students:
          </p>

          <div class="group-content mb-3">
            <h4 class="title mb-2">Universities</h4>
            <div class="sub-title mb-2 jc-text-primary">Quality:</div>
            <p>
              Australian universities are known for their world-class education and research programs.
              They offer a wide range of undergraduate and postgraduate courses in various fields, including STEM,
              humanities, business, and the arts.
            </p>
          </div>
          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Benefits:</div>
            <p>
              Australian university degrees are recognized globally, providing graduates with excellent career
              prospects.
              Universities often have strong links to industry, offering internships and research opportunities.
              The multicultural environment of universities promotes diversity and cultural exchange.
            </p>
          </div>
          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Entry Requirements for International Students:</div>
            <p>
              International students must obtain a student visa to study at an Australian university.
              Most universities require international students to meet English language proficiency requirements through
              tests like IELTS or TOEFL.
              Academic entry requirements vary depending on the course and institution, and students should refer to the
              university's specific admissions criteria.
            </p>
          </div>
          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Accommodation:</div>
            <p>
              Most Australian universities provide on-campus accommodation options for international students.
              These accommodations can include residential halls, dormitories, or student apartments.
              Off-campus housing options are also widely available, such as rental apartments, shared houses, and
              homestays.
            </p>
          </div>

          <div class="group-content mt-5">
            <p>
              <strong>
                It's important for international students to research and carefully choose the education segment that
                aligns with their academic and career goals. Additionally, they should check the entry requirements and
                procedures of their chosen institution to ensure a smooth application process and successful enrollment
                in Australia.
              </strong>
            </p>
          </div>


        </div>

      </div>
    </div>

  </div>

</template>

<script>
// import NoData from "@/components/dashboard/NoData.vue";

export default {
  name: "EduUniversity",
  // components: {NoData},
};

</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

.tb-content {
  display: grid;
  align-items: center;
  min-height: 150px;
}

.tb-content h2 {
  font-size: 30px;
  font-weight: 200;
}

/* Title banner end */

.tc-box {
  padding: 50px 0;
  max-width: 800px;
  margin: 0 auto;
}
</style>


