<template>
  <div class="page-wrapper auth">
    <div v-if="msg" id="message_popup" class="alert alert-success">
      <h4>{{ msg }}</h4>
    </div>

    <div v-if="delete_msg" id="delete_popup" class=" ">
      <h4>Sure to Detete ?</h4>

      <div>
        <div class="row mt-4">
          <div class="col-lg-6">
            <button @click.prevent="cancel_btn" class="theme-btn btn-style-one">
              Cancle
            </button>
          </div>
          <div class="col-lg-6">
            <button
              @click.prevent="confirm_btn"
              class="theme-btn btn-style-one"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <auth-header-view @get_message="message_show"></auth-header-view>
    <router-view @get_message="message_show"></router-view>
    <auth-footer-view @get_message="message_show"></auth-footer-view>
  </div>
</template>

<script>
import AuthHeaderView from "@/components/auth/AuthHeaderView.vue";
import AuthFooterView from "@/components/auth/AuthFooterView.vue";

export default {
  name: "AuthMasterView",
  components: { AuthHeaderView, AuthFooterView },

  data() {
    return {
      msg: "",
      delete_msg: "",
      is_delete: false,
      dataToPass: false,
    };
  },

  provide() {
    return {
      delete_status: "",
    };
  },
  methods: {
    message_show(message) {
      this.msg = message;

      this.message_hide();
    },

    confirm_btn() {
      if (this.dataToPass == false) {
        this.dataToPass = true;
      } else {
        this.dataToPass = false;
      }

      document.getElementById("delete_popup").style.display = "none";
    },

    cancel_btn() {
      document.getElementById("delete_popup").style.display = "none";
    },

    delete_show(message) {
      this.delete_msg = message;

      this.delete_popup_show();

      if (document.getElementById("delete_popup")) {
        const box = document.getElementById("delete_popup");
        box.style.display = "block";
      }
    },

    delete_popup_show() {},

    message_hide() {
      setTimeout(() => {
        document.getElementById("message_popup").style.display = "none";
      }, 2000);

      if (document.getElementById("message_popup")) {
        const box = document.getElementById("message_popup");
        box.style.display = "block";
      }
    },
  },
};
</script>

<style></style>
