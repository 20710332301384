<template>
  <div class="need-help-wrap">
    <!-- Title banner -->
    <div class="title-banner">
      <div class="container">
        <div class="tb-content">
          <h2>Education School</h2>
          <!--          <p></p>-->
        </div>

      </div>
    </div>

    <div class="container">
<!--      <no-data></no-data>-->
      <div class="tc-box">
        <div class="content-wrap">
          <p class="mb-3">
            Australia's education system consists of three main segments: Schools, Vocational Education and Training
            (VET), and Universities. Each segment offers unique qualities, benefits, and entry requirements for
            international students:
          </p>

          <div class="group-content mb-3">
            <h4 class="title mb-2">Schools</h4>
            <div class="sub-title mb-2 jc-text-primary">Quality:</div>
            <p>
              Australian schools offer a high-quality education with a strong focus on academic and personal development.
              The school curriculum is designed to provide a well-rounded education, including core subjects and a wide
              range of extracurricular activities.
            </p>
          </div>

          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Benefits:</div>
            <p>
              Schools in Australia offer a safe and supportive learning environment.
              Students have access to qualified teachers, modern facilities, and technology.
              International students can improve their English language skills and prepare for further education.
            </p>
          </div>

          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Entry Requirements for International Students:</div>
            <p>
              To enroll in a primary or secondary school, international students typically need to obtain a student
              visa.
              Students may also need to provide proof of English proficiency, either through a language test or by
              attending an English language course.
              Different schools may have varying entry requirements, so it's essential to check with the specific
              school.
            </p>
          </div>

          <div class="group-content mb-3">
            <div class="sub-title mb-2 jc-text-primary">Accommodation:</div>
            <p>
              Many primary and secondary schools in Australia do not provide on-campus accommodation. <br>
              International students typically live with host families, in homestays, or arrange private rental
              accommodation. It's essential to research and plan accommodation in advance, considering factors like proximity to the
              school and budget.
            </p>
          </div>

          <div class="group-content mt-5">
            <p>
              <strong>
                It's important for international students to research and carefully choose the education segment that
                aligns with their academic and career goals. Additionally, they should check the entry requirements and
                procedures of their chosen institution to ensure a smooth application process and successful enrollment
                in Australia.
              </strong>
            </p>
          </div>


        </div>

      </div>
    </div>

  </div>

</template>

<script>
// import NoData from "@/components/dashboard/NoData.vue";

export default {
  name: "EduSchool",
  // components: {NoData},
};

</script>

<style scoped>
/* Title banner */
.title-banner {
  background-image: linear-gradient(to right, #FF7600, rgba(255, 198, 128, 0.5)),
  url('../../assets/light.jpg');
  background-color: #FF7600;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  min-height: 150px;
  color: white;
}

.tb-content {
  display: grid;
  align-items: center;
  min-height: 150px;
}

.tb-content h2 {
  font-size: 30px;
  font-weight: 200;
}

/* Title banner end */

.tc-box {
  padding: 50px 0;
  max-width: 800px;
  margin: 0 auto;
}

</style>


