<template>
  <header class="main-header">
    <div class="container">
      <div
        id="top-bar"
        style="background-color: hsl(0, 0%, 100%); height: 70px; display: none"
      >
        <div style="padding: 0px 42px">
          <div class="row pt-1">
            <div class="col-md-4">
              <a href="/">
                <img src="images/logo/logo2.png" />
              </a>
            </div>
            <div class="col-md-4"></div>

            <div class="col-md-4 p-3 text-end">
              <div class="btn-box">
                <!-- <a v-if="name != ''" href="/recruiter-postjob" class="theme-button p-3 "
              >JOB POST</a
            >
            <a v-else href="/login" class="theme-button  p-2">JOB POST</a> -->

                <div v-if="name != ''" class="dropdown dashboard-option">
                  <a
                    class="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      height="20px"
                      width="20px"
                      v-if="logo != ''"
                      :src="logo"
                      alt="avatar"
                      class="thumb"
                    />
                    <span class="name text-grey">{{ name }}</span>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="dashboard"
                        ><i class="la la-user-alt"></i>Dashboard</a
                      >
                    </li>

                    <li>
                      <a @click.prevent="logout" href="#"
                        ><i class="la la-sign-out"></i>Logout</a
                      >
                    </li>
                    <!--li><a href="index.html"><i class="la la-trash"></i>Delete Profile</a></li-->
                  </ul>
                </div>

                <a
                  v-else
                  href="/login"
                  style="
                    padding: 8px;
                    color: #ff5c41;
                    border: 1px solid #ff5c41;
                    background-color: white;
                    border-radius: 8px;
                  "
                  >LOGIN</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main box -->
      <div class="main-box">
        <div class="logo-box">
          <div class="logo">
            <a href="/">
              <img src="images/jobchain-logo-color.svg" alt="JobChain logo" />
            </a>

            <!-- <span style="font-size: 150%;font-weight: bold;color: white;" >NEWSITE</span> -->
          </div>
        </div>

        <nav class="nav main-menu py-3">
          <ul class="navigation" id="navbar">
            <!--            <li><a href="/jobs">Jobs</a></li>-->
            <li><a href="/#services">Services</a></li>
            <!--            <li><a href="/postajob">Post job</a></li>-->
            <li><a href="/course">Course</a></li>
<!--            <li><a href="/admission">Admission</a></li>-->
            <li><a href="/blogs">Blogs</a></li>
            <!--            <li><a href="/news-room">Blogs</a></li>-->
            <!--            <li><a href="/career-advice">Career Advice</a></li>-->
            <!--            <li><a href="/news-room">News room</a></li>-->
            <li><a href="/needhelp">Help?</a></li>

            <!-- Only for Mobile View -->
            <li class="mm-add-listing">
              <!-- <a href="add-listing.html" class="theme-btn btn-style-one"
                >Job Post</a
              > -->
              <span>
                <span class="contact-info">
                  <span class="phone-num"
                    ><span>Call us</span
                    ><a href="tel:+44 7495 587992">+44 7495 587992</a></span
                  >
                  <span class="address"
                    >128-130 the Grove,Startford,London,England,E15 1NS
                  </span>
                  <a href="mailto:support@jobchains.com" class="email"
                    >support@jobchain.co.uk</a
                  >
                </span>
                <span class="social-links">
                  <a href="#"><span class="fab fa-facebook-f"></span></a>
                  <a href="#"><span class="fab fa-twitter"></span></a>
                  <a href="#"><span class="fab fa-instagram"></span></a>
                  <a href="#"><span class="fab fa-linkedin-in"></span></a>
                </span>
              </span>
            </li>
          </ul>
        </nav>
        <!-- Main Menu End-->

        <div class="outer-box">
          <!-- Add Listing -->
          <!-- <a href="candidate-dashboard-cv-manager.html" class="upload-cv text-white"> UPLOAD YOUR CV</a> -->
          <!-- Login/Register -->
          <div class="btn-boxx text-endx">
            <!-- <a v-if="name != ''" href="/recruiter-postjob" class="theme-button"
              >JOB POST</a
            >
            <a v-else href="/login" class="text-white text-end py-3"
              >Employer/Post job <i class="fa-solid fa-arrow-right"></i>
            </a> -->

            <!-- <div v-if="name != ''" class="dropdown dashboard-option">
              <a
                class="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  height="20px"
                  width="20px"
                  v-if="logo != ''"
                  :src="logo"
                  alt="avatar"
                  class="thumb"
                />
                <span class="name text-grey">{{ name }}</span>
              </a>
              <ul class="dropdown-menu">

                <li>
                  <a @click.prevent="dashboard"
                    ><i class="la la-user-alt"></i>Dashboard</a
                  >
                </li>

                <li>
                  <a @click.prevent="logout" href="#"
                    ><i class="la la-sign-out"></i>Logout</a
                  >
                </li>
              </ul>
            </div>

            <a
              v-else
              href="/login"
              style="
                padding: 10px;
                color: #ff5c41;
                background-color: white;
                border-radius: 8px;
                min-width: 100px;
                text-align: center;
              "
              >LOGIN</a> -->
          </div>
        </div>
      </div>

      <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo">
          <a href="/"
            ><img class="mt-2" src="images/logo/logo.png" alt="" title=""
          /></a>
        </div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">
          <div class="outer-box text-black">
            <!-- Login/Register -->
            <div class="login-box">
              <a class="text-black" href="/login">
                <span class="las la-user fs-1"></span>
              </a>
            </div>

            <a href="#nav-mobile" class="mobile-nav-toggler text-black">
              <span class="flaticon-menu-1"></span>
            </a>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->
      <div id="nav-mobile"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AuthHeaderView",
  data() {
    return {};
  },
  methods: {},
  mounted: function () {},
};
</script>

<style></style>
